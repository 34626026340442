import styled from "@emotion/styled";
import { Box } from "Components";
import React from "react";

export const HomeWrapper = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;

    .photo {
        position: relative;
        height: 347px;
        max-height: 347px;

        .logo {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .titlesection {
        position: relative;
        margin-top: 45px;
        margin-bottom: 25px;
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        align-content: center;
        justify-content: center;

        .title {
            position: relative;
            line-height: 22px;
            width: 100%;
            text-align: center;
            font-weight: 200;
            font-size: 32px;
            letter-spacing: -0.7pt;
            /* background-color: yellow; */
        }

        .subtitle {
            position: relative;
            font-family: Montserrat;
            font-weight: 200;
            font-size: 21px;
            letter-spacing: -0.46pt;

            /* background-color: pink; */
        }
    }

    .bigbluebutton {
        position: relative;
        margin-bottom: 52px;
        text-decoration: none;
        font-family: Montserrat;
        font-weight: 200;
        font-size: 66px;
    }

    .instructions {
        position: relative;
        margin-bottom: 59px;
    }
`;
