import styled from "@emotion/styled";
import Select from "react-select";
import { theme } from "Styles";

export const RoofcareSelect = styled(Select)`
    .editselect__option {
        color: black !important;

        &.editselect__option--is-focused,
        &.editselect__option--is-selected {
            color: black !important;
            background: #efefef;
        }

        &.editselect__option--is-selected {
            color: white !important;
            background: #1d7698;
        }
    }
`;
