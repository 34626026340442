import styled from "@emotion/styled";
import { AssistantCommon } from "../Assistant-style";

export const EndWrapperWrapper = styled(AssistantCommon)`
    margin-bottom: 30px;
    width: auto;

    .autoCompleteInput {
        line-height: 1.5;
        padding: 10px 0 10px 10px;
        width: 100%;
        border-radius: 6pt;
        border: 0;
    }
`;

export const ClientInfo = styled.div`
    width: 100%;

    border-bottom: 1pt dashed white;

    padding-bottom: 15px;
    margin-bottom: 15px;
`;

export const ClientDetails = styled.div`
    width: 100%;

    margin-bottom: 215px;
`;
