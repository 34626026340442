import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Label } from "Application";
import { AssistantViewModel } from "../AssistantViewModel";
import { AssistantStepView, PreviousNext } from "../Assistant-style";
import { AssistantSteps } from "../AssistantSteps";
import { NextStepButton, PrevStepButton } from "Components/Buttons";
import stepIcon from "Assets/step06.svg";
import stepIconTablet from "Assets/step06tablet.svg";
import { ActivationInstructionsWrapper } from "./ActivationInstructionsStepView-style";
import { ActivationInstructionsStepViewModel } from "./ActivationInstructionsStepViewModel";
import { RoofcareInput } from "Styles/BaseStyles";
import ButtonPress from "Assets/Button-Press.png";
import { PhotoView } from "./PhotoView";
import { LoaderBase } from "Components/Primitives/Loaders/LoaderBase";
import { CoreStoreInstance } from "@shoothill/core";

export interface IStepActivationInstructions {
    parentViewModel: AssistantViewModel;
}

export const ActivationInstructionsStepView: React.FC<IStepActivationInstructions> = observer(({ parentViewModel }) => {
    const [viewModel] = useState<ActivationInstructionsStepViewModel>(parentViewModel.activationInstructionViewModel);

    useEffect(() => {
        if (viewModel.isModelValid(true, true) === true) {
            CoreStoreInstance.HideInfoBar();
        }
    }, [viewModel.isModelValid(true, true)]);

    const onPrev = (step: AssistantSteps) => {
        // We can go back regardless of state of the local viewModel
        parentViewModel.setStep(step);
    };

    const onNext = async (step: AssistantSteps) => {
        viewModel.setValue("firstValidate", false);

        let isValid: boolean = viewModel.isModelValid(true, true);

        if (isValid === true) {
            let promise: any = await parentViewModel.completeInstallationCommand(step);
        }
    };

    const allowNextStep: boolean = true; // TODO Need to only do this if the local viewModel is valid

    return (
        <>
            {viewModel.apiClient.IsBusy === true && <LoaderBase isOpen={viewModel.apiClient.IsBusy} className="loadingscreen" displayText="Loading" />}
            <ActivationInstructionsWrapper padding={viewModel.isMobile ? "0 20px 0 20px" : "0px"}>
                <AssistantStepView>
                    <img className="icon" width={"100%"} src={viewModel.isMobile ? stepIcon : stepIconTablet} alt="activation instructions" />
                </AssistantStepView>

                <div>
                    <p style={{ marginBottom: "10px" }}>Sim Id: {parentViewModel.model.simId}</p>
                </div>

                <Label fontStyle={"h1"} color={"headerText"}>
                    Step 6
                </Label>

                <Label fontStyle={"h2"} color={"headerText"} mb={"16px"}>
                    Activation instructions
                </Label>

                <div className="instructionrow">
                    <div className="number">1.</div>
                    <div className="instructionSpacing">Clear Gutter</div>
                </div>
                <div className="instructionrow">
                    <div className="number">2.</div>
                    <div className="instructionSpacing">Ensure no debris or standing water is present beneath the sensor</div>
                </div>
                <div className="instructionrownodash">
                    <div className="number">3.</div> <div className="instructionSpacing">Press Button</div>
                </div>

                <div className="instructionrowwithimage">
                    <img src={ButtonPress} width={"100%"}></img>
                </div>

                <div className="finalinstructionrow">
                    <div className="number">4.</div> <div>Wait for confirmation text message</div>
                </div>

                <>
                    <div>
                        <Label fontStyle={"h2"} color={"headerText"}>
                            Install Notes
                        </Label>
                        <RoofcareInput
                            displayName="Notes"
                            multiline={true}
                            placeholder="Please add any notes about the installation"
                            command={viewModel.updateLocationDescription}
                            value={() => viewModel.model.installationNotes}
                            validationMessage={() => viewModel.getError("installationNotes")}
                        />
                    </div>

                    <div>
                        <Label fontStyle={"h3"} color={"headerText"}>
                            Photos
                        </Label>

                        <PhotoView
                            photoGalleryArr={viewModel.getPhotos}
                            addPhotos={viewModel.addPhotos}
                            deletePhoto={viewModel.deletePhoto}
                            setError={parentViewModel.setModalError}
                        />
                    </div>

                    <div className="drainagephotos">
                        <Label fontStyle={"h3"} color={"headerText"}>
                            Drainage Photos
                        </Label>

                        <PhotoView
                            photoGalleryArr={viewModel.getDrainagePhotos}
                            addPhotos={viewModel.addDrainagePhotos}
                            deletePhoto={viewModel.deleteDrainagePhoto}
                            setError={parentViewModel.setModalError}
                        />
                    </div>

                    <PreviousNext>
                        <PrevStepButton disabled={false} step={AssistantSteps.InstallHeight} buttonText={"Previous step"} onClick={onPrev} boxShadow={"0pt 3pt 6pt #00000059"} />
                        <NextStepButton
                            disabled={!allowNextStep}
                            step={AssistantSteps.End}
                            buttonText={"Complete installation"}
                            onClick={onNext}
                            boxShadow={"0pt 3pt 6pt #00000059"}
                        />
                    </PreviousNext>
                </>
            </ActivationInstructionsWrapper>
        </>
    );
});
