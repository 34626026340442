import styled from "@emotion/styled";
import { theme } from "Styles";
import { AssistantCommon } from "../Assistant-style";

export const GutterDetailsWrapper = styled(AssistantCommon)`
    margin-bottom: 30px;
    width: auto;
`;

export const GutterType = styled.div`
    padding: 15px 0;

    /* border-bottom: 1px dashed ${theme.palette.common.white}; */
`;

export const GutterTypeRow = styled.div`
    display: flex;
    max-height: 50px;
    flex: 1 0 0;
    flex-direction: row;
    justify-content: space-between;
    color: ${theme.palette.common.white};

    border-radius: 25pt;
    border: 2px solid ${theme.palette.common.white};

    .switchbutton {
        z-index: 4;
        margin-top: -6px;
        margin-left: -130px;
    }

    background-color: rgba(49.8, 42.6, 30.1, 0.1);

    .pitched {
        min-width: 50%;
        border-radius: 25pt;
        &.selected {
            background-color: #337ab7;
            cursor: default;
        }
        cursor: pointer;
    }

    .flat {
        margin-left: -130px;
        z-index: 1;
        min-width: 50%;
        border-radius: 25pt;
        &.selected {
            background-color: #337ab7;
            cursor: default;
        }

        display: flex;
        flex: 0 0 0;
        width: 50%;

        justify-content: end;
        cursor: pointer;
    }

    .roofType {
        vertical-align: center;
        line-height: 48px;
        padding: 0 15px;
    }
`;

/* import ThreeSideL from "Assets/3SideL.png";
import ThreeSideR from "Assets/3SideR.png";
import ThreeSideSym from "Assets/3SideSym.png"; */

export const PitchedProfile = styled.div`
    padding: 20px 0;
    border-bottom: 1px dashed ${theme.palette.common.white};
`;

export const FlatProfile = styled.div`
    padding: 20px 0;
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    justify-content: space-between;

    border-bottom: 1px dashed ${theme.palette.common.white};
    margin-bottom: 15px;
`;

export const DrainageTypeBox = styled.div`
    margin-bottom: 205px;
`;

export const ImageOption = styled.div`
    width: 100%;
    max-height: 36px;
    color: ${theme.palette.common.optionText};
    display: flex;
    flex: 1 0 0;
    flex-direction: row;

    .icon {
        max-height: 36px;
    }

    .profile {
        padding: 7px 15px;
        //To stop quirk in dropdown box
        margin: 0;
    }
`;

export const RoofCarePictureSelect = styled.select`
    position: relative;

    width: 100%;
    height: 38px;
    border-radius: 5pt;
    border-radius: 5pt;

    border: 1px solid ${theme.palette.common.borders};

    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    //height: 40px;
    box-sizing: content-box;

    border-radius: 5pt;

    border: 1px solid ${theme.palette.common.borders};
    transition: 0.5s ease all;
    //check for focus on the child input
    &:hover {
        border: 1px solid ${theme.palette.field.lighter};
    }
    &:focus-within {
        border: 1px solid ${theme.palette.common.focus};
    }

    position: relative;
`;

export const ProfileWrapper = styled.div`
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    //Changed from 100% to auto
    width: auto;
`;

export const ProfileImage = styled.div`
    height: 200px;
    width: 100%;
    background-color: #1b315d;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 15px 20px; */
`;

export const ProfileEntry = styled.div`
    position: relative;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    /* width: 100%; */
    //To stop overflow width:
    width: auto;
    background-color: #1d7698;
    padding: 15px 15px;
`;

export const ProfileEntryRow = styled.div`
    position: relative;
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-sizing: content-box;

    > div {
        padding: 0px 5px 10px 5px;
    }
`;

export const ProfileSplitter = styled.div`
    padding: 15px 0;
    margin-bottom: 15px;
    border-bottom: 1px dashed ${theme.palette.common.white};
`;
