import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Show, theme, Label } from "Application";
import { mergeStyleSets, TooltipHost } from "@fluentui/react";
import { nanoid } from "nanoid";
import { ExclamationIconSVG } from "../Assets/Icons/ExclamationIconSVG";
import { TickIcon } from "../Assets/Icons/TickIconSVG";

const styles = mergeStyleSets({
    root: {
        fontWeight: "600",
        fontSize: "14px",
    },
});

interface Props {
    haveError: boolean;
    displayName?: string;
    validationMessage: () => string;
    style?: any;
}
export const ErrorMessageView: React.FC<Props> = observer((props: Props) => {
    const errorLabel = () => {
        if (props.haveError) {
            return (
                <Box display={"flex"}>
                    <Box>
                        <Box mb={1}>{props.displayName ? <Label color={"error"}>{props.displayName}</Label> : <Label color={"error"}>{props.displayName}</Label>}</Box>
                    </Box>
                    <Label fontStyle={"h5"} color={"error"}>
                        {(props.validationMessage() as string) || "There has been an error"}
                    </Label>
                </Box>
            );
        } else {
            return null;
        }
    };

    return errorLabel();
});

export const ErrorExclamationView: React.FC<Props> = observer((props: Props) => {
    const errorLabel = () => {
        if (props.haveError) {
            return (
                <Box display={"flex"} ml={"-20px"}>
                    <Box>
                        <Box mb={1}>{props.displayName ? <Label color={"error"}>{props.displayName}</Label> : <Label color={"error"}>{props.displayName}</Label>}</Box>
                    </Box>
                    <TooltipHost
                        tooltipProps={{
                            calloutProps: {
                                gapSpace: 0,
                                styles: {
                                    beak: { background: theme.palette.error.main },
                                    beakCurtain: { background: theme.palette.error.main },
                                    calloutMain: { background: theme.palette.error.main },
                                },
                            },
                        }}
                        content={
                            <Label fontStyle={"h5"} color={"white"}>
                                {(props.validationMessage() as string) || "There has been an error"}
                            </Label>
                        }
                        id={nanoid()}
                        styles={{ root: { display: "inline-block" } }}
                    >
                        <Box mt={"-3px"}>
                            <ExclamationIconSVG color={"white"} backgroundcolor={theme.palette.error.main} />
                        </Box>
                    </TooltipHost>
                </Box>
            );
        } else {
            return null;
        }
    };

    return errorLabel();
});
