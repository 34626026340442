export class AppSettings {
    public allowWebCrawlers: boolean = false;
    public forgotPasswordExpiryMins: number = 0;
    public jwtExpiryMins: number = 0;
    public jwtIssuer: string = "";
    public jwtSecret: string = "";
    public refreshExpiryMinutes: number = 0;
    public setNwebSecHeaders: boolean = false;
    public token: string = "";
    public useBasicAuthentication: boolean = false;
    public useDatabase: boolean = true;
    public useHangFire: boolean = true;
    public useSerilog: boolean = false;
    public version: string = "0.0.0";
}
