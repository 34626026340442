import React from "react";
import { BrowserHistory, Action, Location } from "history";
import { Route, RouteObject, Router, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { DefaultLayoutNoFooter } from "Views/Layouts";
import { AppUrls } from "AppUrls";
import { HomeView } from "Views/Home/HomeView";
import { InstructionsView } from "Views/Instructions/InstructionsView";
import { AssistantView } from "Views/Assistant/AssistantView";
import { BackTestView } from "Views/Samples/BackTest";

interface CustomRouterProps {
    basename: string | undefined;
    children?: JSX.Element;
    history: BrowserHistory;
}

interface CustomRouterState {
    action: Action;
    location: Location;
}

export const CustomBrowserRouter: React.FC<CustomRouterProps> = (props: CustomRouterProps) => {
    const [state, setState] = React.useState<CustomRouterState>({
        action: props.history.action,
        location: props.history.location,
    });

    React.useLayoutEffect(() => props.history.listen(setState), [props.history]);
    // Original way of doing things return <Router basename={props.basename} children={props.children} location={state.location} navigationType={state.action} navigator={props.history} />;

    let router = createBrowserRouter(
        createRoutesFromElements(
            <Route element={<DefaultLayoutNoFooter />}>
                <Route path={AppUrls.Client.Home} index element={<HomeView />} />
                {/*   <Route path={AppUrls.Client.BackTest} index element={<BackTestView />} /> */}
                <Route path={AppUrls.Client.Instructions} index element={<InstructionsView />} />
                <Route path={AppUrls.Client.Assistant.Start} index element={<AssistantView />} />
            </Route>,
        ),
        { basename: props.basename },
    );

    return <RouterProvider router={router} />;
};
