import styled from "@emotion/styled";
import { theme } from "Styles";

export const ModalHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    justify-content: left;
    height: 48px;
    background-color: ${theme.palette.common.roofcareMain};
    color: ${theme.palette.common.white};
    .icon {
        max-width: 42px;
        padding: 12px;

        img {
            max-width: 19px;
        }

        /* background-color: green; */
    }

    .title {
        padding: 15px;
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        line-height: 14px;
    }

    .close {
        padding: 12px;
        font-size: 14px;
        max-width: 42px;
    }
    .main-132 {
        min-height: fit-content;
    }
`;

export const ModalBody = styled.div`
    height: max-content;
    padding: 15px;
    background-color: ${theme.palette.common.roofcareMain};
    color: ${theme.palette.common.white};
    text-align: center;

    .errorLine {
        label {
            line-height: 24px !important;
        }
    }

    .errorCloseButton {
        color: ${theme.palette.common.white};
        background-color: ${theme.palette.common.next};
        border-radius: 47px;
        max-width: 170px;
        height: 50px;
        border: none;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;

        &.notfirst {
            margin-left: 15px;
        }
    }
`;
