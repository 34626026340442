import styled from "@emotion/styled";
import { jsx, css, keyframes } from "@emotion/react";
import { Input } from "Components/Primitives/Edits";
import { theme } from "./AppTheme";

export const StaticBGImg = styled.img`
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 1024px;
    z-index: -1;

    /* Set up proportionate scaling */
    width: 100%;
    height: auto;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
    @media screen and (max-width: 1024px) {
        /* Specific to this particular image */
        left: 50%;
        margin-left: -512px; /* 50% */
    }
`;

export const LinkBox = styled.div`
    padding: 5px;
    cursor: pointer;
`;

export const BounceAnimation = keyframes`
	0% { margin-bottom: 0; }
	50% { margin-bottom: 15px }
	100% { margin-bottom: 0 }
`;

export const DotWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const H1Styled = styled.h1`
    font-weight: 300;
    letter-spacing: -0.7pt;
    margin-top: 5px;
`;

export const H2Styled = styled.h2`
    font-weight: 300;
    letter-spacing: -0.46pt;
    margin-bottom: 20px;
    line-height: 0.8;
    font-size: 20px;
`;

export const H3Styled = styled.h2`
    font-weight: 300;
    font-size: 15px;
    line-height: 0.8;
`;

export const Dot = styled.div<any>`
    background-color: black;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    /* Animation */
    animation: ${BounceAnimation} 0.5s linear infinite;
    animation-delay: ${(props) => props.delay};
`;

export const RoofcareInput = styled(Input)`
    border-radius: 5pt;

    .profileValues {
        max-width: 33%;
    }

    input,
    textarea {
        color: ${theme.palette.field.light};
        width: 100% !important;
        padding-right: 0;
        background-color: green;
    }

    .noClear {
        input,
        textarea {
            color: ${theme.palette.field.light};
            width: 100% !important;
            padding-right: 0;
            background-color: green;
        }
    }
`;
