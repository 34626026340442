import { GutterDetailsStepViewModel } from "./GutterDetailsStep/GutterDetailsStepViewModel";
import { SensorLocationStepViewModel } from "./SensorLocationStep/SensorLocationStepViewModel";
import { AssistantSteps } from "./AssistantSteps";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { InstallationModel, InstallationModelValidator } from "./InstallationModel";
import { GetQRCodeDetailsRequest, GetQRCodeDetailsResponse, ProcessQREndpoint } from "./Endpoints/ProcessQREndpoint";
import { ContractorStepViewModel } from "./ContractorStep/ContractorStepViewModel";
import { EndUserStepViewModel } from "./EndUserStep/EndUserStepViewModel";
import { InstallHeightViewModel } from "./InstallHeightStep/InstallHeightViewModel";
import { ActivationInstructionsStepViewModel } from "./ActivationInstructionStep/ActivationInstructionsStepViewModel";
import { CompleteInstallationEndpoint, CompleteInstallationRequest, CompleteInstallationResponse } from "./Endpoints/CompleteInstallationEndpoint";

export class AssistantViewModel extends ViewModelBase<InstallationModel> {
    public apiClient = new APIClient();
    private processQREndpoint: ProcessQREndpoint = new ProcessQREndpoint();
    private completeInstallationEndpoint: CompleteInstallationEndpoint = new CompleteInstallationEndpoint();

    public assistantStep: AssistantSteps = AssistantSteps.Start;
    public isInError: boolean = false;
    public errorMessage1: string = "";
    public errorMessage2: string = "";

    // Step 1
    public contractorViewModel: ContractorStepViewModel = new ContractorStepViewModel();
    // Step 2
    public endUserViewModel: EndUserStepViewModel = new EndUserStepViewModel(this);
    // Step 3
    public sensorLocationViewModel: SensorLocationStepViewModel = new SensorLocationStepViewModel(this);
    // Step 4
    public gutterDetailsViewModel: GutterDetailsStepViewModel = new GutterDetailsStepViewModel();
    // Step 5
    public installHeightViewModel: InstallHeightViewModel = new InstallHeightViewModel();
    // Step 6
    public activationInstructionViewModel: ActivationInstructionsStepViewModel = new ActivationInstructionsStepViewModel();

    // Error Modal

    ///
    /// Constructor
    ///
    constructor() {
        super(new InstallationModel());
        this.setValidator(new InstallationModelValidator());
        makeObservable(this, {
            assistantStep: observable,
            isInError: observable,
            hasData: computed,
            isBlocking: computed,
            clear: action,
            clearError: action,
            setStep: action,
            setModalError: action,
        });
    }

    public get isBlocking(): boolean {
        let retVal: boolean = false;

        if (this.assistantStep > AssistantSteps.Start) {
            if (this.contractorViewModel.isModelDirty == true) {
                retVal = true;
            }
        }
        return retVal;
    }

    public setStep(step: AssistantSteps) {
        // Do any step pre-processing here

        if (this.assistantStep === AssistantSteps.EndUser && step === AssistantSteps.SensorLocation) {
            // if (this.model.draggedLat === 0 && this.model.draggedLng === 0) {
            if (
                this.endUserViewModel.model.selectedPropertyFromSearch !== undefined &&
                this.endUserViewModel.model.selectedPropertyFromSearch.latitude !== undefined &&
                this.endUserViewModel.model.selectedPropertyFromSearch.longitude !== undefined
            ) {
                if (
                    this.model.latFromAddress === this.endUserViewModel.model.selectedPropertyFromSearch?.latitude &&
                    this.model.lngFromAddress === this.endUserViewModel.model.selectedPropertyFromSearch?.longitude
                ) {
                    //Don't do anything
                } else {
                    this.model.latFromAddress = this.endUserViewModel.model.selectedPropertyFromSearch?.latitude;
                    this.model.lngFromAddress = this.endUserViewModel.model.selectedPropertyFromSearch?.longitude;
                    this.model.draggedLat = this.endUserViewModel.model.selectedPropertyFromSearch?.latitude;
                    this.model.draggedLng = this.endUserViewModel.model.selectedPropertyFromSearch?.longitude;
                }
            }
        } // else it has a value, so we have a problem
        // TODO  it could be ok. We could be at the same address so we don't want to mess with it
        // if we have changed the address, then the device will be at the old address so we do want to change it.
        //}

        // Adjust the step
        this.assistantStep = step;
    }

    public clearError = () => {
        this.isInError = false;
        this.errorMessage1 = "";
        this.errorMessage2 = "";
    };

    public clear = () => {
        this.model.clear();
        this.assistantStep = AssistantSteps.Start;
        this.isInError = false;
        this.errorMessage1 = "";
        this.errorMessage2 = "";

        // Just throw away the old viewmodels and recreate.
        this.contractorViewModel = new ContractorStepViewModel();
        this.endUserViewModel = new EndUserStepViewModel(this);
        this.sensorLocationViewModel = new SensorLocationStepViewModel(this);
        this.gutterDetailsViewModel = new GutterDetailsStepViewModel();
        this.installHeightViewModel = new InstallHeightViewModel();
        this.activationInstructionViewModel = new ActivationInstructionsStepViewModel();
    };

    public get hasData(): boolean {
        return this.model.hasData;
    }

    public processQRCodeCommand = async (): Promise<boolean> => {
        if (this.apiClient.IsBusy === false) {
            this.clearError();
            await this.apiClient.sendAsync<GetQRCodeDetailsRequest, GetQRCodeDetailsResponse>(this.processQREndpoint, this.model);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let retVal: GetQRCodeDetailsResponse = this.apiClient.Response();
                    // Clear out the old data before
                    this.model.clear();
                    this.contractorViewModel.clear();
                    this.endUserViewModel.clear();
                    this.sensorLocationViewModel.clear();
                    this.gutterDetailsViewModel.clear();
                    this.installHeightViewModel.clear();
                    this.activationInstructionViewModel.clear();

                    if (retVal !== null && retVal !== undefined) {
                        // General on each page
                        if (retVal.device !== null && retVal.device !== undefined) {
                            this.setValue("simId", retVal.device.simId);
                            this.setValue("deviceDBId", retVal.device.id);
                            this.setValue("projectId", retVal.device.projectId);
                        }

                        // Step 1 Contractor
                        if (retVal.contractor !== null && retVal.contractor !== undefined) {
                            this.contractorViewModel.load(retVal.contractor);
                        }

                        // Step 2 Client / End user
                        if (retVal.client !== null && retVal.client !== undefined) {
                            if (retVal.properties !== null && retVal.properties !== undefined) {
                                this.endUserViewModel.load(retVal.client, retVal.properties);
                            } else {
                                this.endUserViewModel.load(retVal.client, []);
                            }
                        }

                        // SJ said not to allow re-loading of the data.

                        // Step 3 Sensor Location
                        /*                         if ((retVal.client !== null && retVal.client !== undefined) || (retVal.device !== null && retVal.device !== undefined)) {
                        }

                        // Step 4 Gutter Details
                        if (retVal.deviceStatus !== null && retVal.deviceStatus !== undefined) {
                        }

                        // Step 5 Install Height
                        if (retVal.deviceStatus !== null && retVal.deviceStatus !== undefined) {
                        } */

                        // Step 6 Activation Instructions
                        if (
                            retVal.installationCommissioned !== null &&
                            retVal.installationCommissioned !== undefined &&
                            retVal.installationCommissionImages !== null &&
                            retVal.installationCommissionImages !== undefined
                        ) {
                            this.isInError = true;
                            this.errorMessage1 = "This QR code has been used before.";
                            this.errorMessage2 = "Please contact RoofCare for advice.";
                            return false;
                        }

                        this.assistantStep = AssistantSteps.Contractor;
                        return true;
                    } else {
                        this.isInError = true;
                        this.errorMessage1 = "Failed to validate the QR Code. Please try again.";
                        this.errorMessage2 = "If the problem persists. Please contact RoofCare for advice.";
                        return false;
                    }
                });
            } else {
                this.model.clear();
                return false;
            }
        }
        return true;
    };

    public completeInstallationCommand = async (step: AssistantSteps): Promise<void> => {
        if (this.apiClient.IsBusy === false) {
            this.clearError();
            await this.apiClient.sendAsync<CompleteInstallationRequest, CompleteInstallationResponse>(this.completeInstallationEndpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let retVal: CompleteInstallationResponse = this.apiClient.Response();
                    // TODO SOMETHING

                    // Move to the next step because we was successful
                    this.setStep(step);
                });
            } else {
                this.isInError = true;
                this.errorMessage1 = "Failed to store the installation information. Please try again.";
                this.errorMessage2 = "If the problem persists. Please contact RoofCare for advice.";
            }
        }
    };

    public setModalError = (message1: string, message2: string) => {
        this.errorMessage1 = message1;
        this.errorMessage2 = message2;
        this.isInError = true;
    };
}
