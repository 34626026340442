import { computed, makeObservable } from "mobx";
import { FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { EndUserStepModel, EndUserStepModelValidator } from "./EndUserStepModel";
import { Client, Property } from "Models";
import { formatAddress } from "Utils/format";
import { AssistantViewModel } from "../AssistantViewModel";

export class EndUserStepViewModel extends ViewModelBase<EndUserStepModel> {
    public apiClient = new APIClient();

    constructor(parent: AssistantViewModel) {
        super(new EndUserStepModel());
        // changed from .EndUserStepModel
        this.setValidator(new EndUserStepModelValidator());
        makeObservable(this, {
            getPropertyOptions: computed,
            getSelectedPropertyId: computed,
        });
    }

    public get getSelectedPropertyId(): string {
        return this.model.selectedPropertyId;
    }

    public get getSelectedPropertyFromSearch(): Property | undefined {
        return this.model.selectedPropertyFromSearch;
    }

    public updateSelectedPropertyId: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.setSelectedProperty(value.key);
    });

    public get getPropertyOptions(): KeyValuePair<any>[] {
        let retVal: KeyValuePair<any>[] = [];

        let notselected: KeyValuePair<any> = {
            key: "",
            text: "Not Listed",
        };
        retVal.push(notselected);

        this.model.clientProperties.map((property: Property) => {
            let kv: KeyValuePair<any> = {
                key: property.id,
                text: formatAddress(property, true),
            };
            retVal.push(kv);
        });

        return retVal;
    }

    public clear() {
        this.model.clear();
    }

    public load(contractor: Client, properties: Property[]) {
        this.model.load(contractor, properties);
    }

    private updateField(fieldName: keyof FieldType<EndUserStepModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public updateCl: ICommand = new RelayCommand((value: string) => {
        this.updateField("clientName", value);
    });

    public updateChosenProperty = (value: google.maps.places.PlaceResult) => {
        let propertyDetails = value;

        if (propertyDetails.address_components && propertyDetails.geometry && propertyDetails.geometry.location) {
            let newProperty: Property = {
                id: undefined,
                buildingName: value.name,
                addressLine1: propertyDetails.address_components[0].long_name + " " + propertyDetails.address_components[1].long_name,
                addressLine2: "",
                city: propertyDetails.address_components[2].long_name,
                county: propertyDetails.address_components[3].long_name,
                postcode: propertyDetails.address_components[6].long_name,
                latitude: propertyDetails.geometry.location.lat(),
                longitude: propertyDetails.geometry?.location.lng(),
                clientId: this.model.clientId!,
            };
            this.updateField("selectedPropertyFromSearch", newProperty);
            console.log(this.model.selectedPropertyFromSearch);
        }
    };
}
