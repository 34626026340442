import React, { useRef } from "react";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { InfoBar } from "../../Components/InfoBar";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router";
import { Box } from "../../Components";
import { Header } from "../Header/Header";
import styled from "@emotion/styled";
import background from "../../Assets/background.png";

import { use100vh } from "react-div-100vh";

const LayoutWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;
`;

const LayoutInnerWrapper = styled.div`
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    //Old mobile-only width
    /* max-width: 390px; */
`;

//EN: use empty generic type to get default react props
export const DefaultLayoutNoFooter: React.FC<{}> = observer((props) => {
    const height = use100vh();
    const myWindowHeight = height ? height * 0.95 : "95vh";
    console.log(myWindowHeight);

    if (!props) return <>Props undefined</>;
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <LayoutWrapper>
                    <LayoutInnerWrapper className="mobileMaxwidth">
                        <Header />
                        <Box className={"overflowContainer"} style={{ height: myWindowHeight }}>
                            <InfoBar />
                            <Outlet />
                        </Box>
                    </LayoutInnerWrapper>
                </LayoutWrapper>
            )}
        </>
    );
});
