import styled from "@emotion/styled";
import { AssistantCommon } from "../Assistant-style";

export const ContractorWrapper = styled(AssistantCommon)`
    margin-bottom: 30px;
    width: auto;

    .mobile {
        /* max-width: 100%; */
    }
    .tablet {
        max-width: 512px;
    }
`;
