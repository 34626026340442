/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { Link as RouterLink, NavLink } from "react-router-dom";
import logo from "Assets/shoothillFooterLogo.svg";
import { AppUrls } from "AppUrls";
import { Main, SocialsGrid, ExternalLink } from "./FooterView.styles";
import { Box, Show } from "Components";
import useMediaQuery from "../../Application/Hooks/useMediaQuery";
import { Label } from "Application";

export const Footer: React.FC = () => {
    const isMobile = useMediaQuery("(max-width: 767px)");

    const mainText = (
        <Box textAlign={isMobile ? "center" : "right"} paddingTop={isMobile ? "25px" : "0"} style={{ cursor: "pointer" }}>
            <Box grid dc={"1fr"} rowGap={"20px"}>
                <NavLink to={AppUrls.Client.Home}>
                    <Label fontStyle={"h4"} color={"white"}>
                        Home
                    </Label>
                </NavLink>
                <NavLink to="/faq">
                    <Label fontStyle={"h4"} color={"white"}>
                        How does it work?
                    </Label>
                </NavLink>
                <NavLink to="/contact">
                    <Label fontStyle={"h4"} color={"white"}>
                        Contact
                    </Label>
                </NavLink>
                <NavLink to={AppUrls.Client.Account.Login}>
                    <Label fontStyle={"h4"} color={"white"}>
                        Login
                    </Label>
                </NavLink>
            </Box>
        </Box>
    );

    const copyright = (
        <Box paddingTop={isMobile ? "10px" : "20px"} textAlign={isMobile ? "center" : undefined}>
            <Label color="white" fontStyle={isMobile ? "h1" : "h2"}>
                <strong>&copy; {new Date().getFullYear()} Shoothill Ltd</strong>
            </Label>
        </Box>
    );

    return (
        <Main>
            <Box
                backgroundColor={"#333333"}
                paddingLeft={isMobile ? "10px" : "60px"}
                paddingRight={isMobile ? "10px" : "60px"}
                paddingTop={isMobile ? "10px" : "15px"}
                paddingBottom={isMobile ? "10px" : "15px"}
            >
                <Box grid dc={isMobile ? " 1fr" : "1fr 1fr 1fr"} padding={"10px"}>
                    <img src={logo} alt={"logo"} />
                    <Box>
                        <Box paddingLeft={"25px"}>
                            <SocialsGrid grid dc={isMobile ? "auto" : "10px 1fr"}>
                                <Label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.472" height="22.453" viewBox="0 0 10.472 22.453">
                                        <path
                                            id="Path_555"
                                            data-name="Path 555"
                                            d="M6.922,6.7V4.881A.95.95,0,0,1,7.9,3.816h2.529V0H6.967A4.38,4.38,0,0,0,2.219,4.7V6.745H0v4.482H2.263V22.453H6.745V11.226h3.328l.133-1.775.266-2.707H6.922Z"
                                            fill="#fff"
                                            fillRule="evenodd"
                                        />
                                    </svg>
                                </Label>
                                <Label color={"white"}>
                                    <ExternalLink
                                        target={"_blank"}
                                        style={{
                                            color: "white",
                                            textDecoration: "none",
                                            fontSize: "14px",
                                        }}
                                        href="https://www.facebook.com/Shoothillltd"
                                    >
                                        facebook.com/Shoothillltd/
                                    </ExternalLink>
                                </Label>
                                <Label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.501" height="19.611" viewBox="0 0 24.501 19.611">
                                        <path
                                            id="Path_551"
                                            data-name="Path 551"
                                            d="M24.5,2.324a10.8,10.8,0,0,1-2.857.775A5.026,5.026,0,0,0,23.872.339a10.635,10.635,0,0,1-3.2,1.211A5.233,5.233,0,0,0,16.948,0a5.012,5.012,0,0,0-5.036,4.939,4.127,4.127,0,0,0,.145,1.114A14.245,14.245,0,0,1,1.695.92a5.222,5.222,0,0,0-.678,2.47A4.957,4.957,0,0,0,3.244,7.505,4.587,4.587,0,0,1,.968,6.876v.048a4.535,4.535,0,0,0,.339,1.792,5.027,5.027,0,0,0,3.68,3.051,6.1,6.1,0,0,1-1.307.194,5.363,5.363,0,0,1-.92-.1,5.021,5.021,0,0,0,4.7,3.438,10.345,10.345,0,0,1-6.246,2.131c-.436,0-.775-.048-1.211-.048a14.456,14.456,0,0,0,7.7,2.227A13.955,13.955,0,0,0,21.645,8.522a12.691,12.691,0,0,0,.339-3V4.891A11.5,11.5,0,0,0,24.5,2.324Z"
                                            fill="#fff"
                                            fillRule="evenodd"
                                        />
                                    </svg>
                                </Label>
                                <Label color={"white"}>
                                    <ExternalLink
                                        target={"_blank"}
                                        style={{
                                            color: "white",
                                            textDecoration: "none",
                                            fontSize: "14px",
                                        }}
                                        href="https://www.twitter.com/shoothill"
                                    >
                                        @shoothill
                                    </ExternalLink>
                                </Label>
                                <Label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.778" height="19.804" viewBox="0 0 20.778 19.804">
                                        <path
                                            id="linkedinIcon"
                                            d="M1132.135,133.8V126.6c0-1.461-.422-2.954-2.175-2.954s-2.467,1.493-2.467,2.987V133.8h-4.643V120.623h4.643v1.786a4.709,4.709,0,0,1,4.221-2.175c1.916,0,5.065.909,5.065,6.1V133.8ZM1118.6,118.675a2.351,2.351,0,1,1,2.6-2.338A2.476,2.476,0,0,1,1118.6,118.675ZM1120.9,133.8h-4.643V120.623h4.643Z"
                                            transform="translate(-1116 -114)"
                                            fill="#fff"
                                            fillRule="evenodd"
                                        />
                                    </svg>
                                </Label>
                                <Label color={"white"}>
                                    <ExternalLink
                                        target={"_blank"}
                                        style={{
                                            color: "white",
                                            textDecoration: "none",
                                            fontSize: "14px",
                                        }}
                                        href="https://www.linkedin.com/company/shoothill"
                                    >
                                        linkedin.com/company/shoothill/
                                    </ExternalLink>
                                </Label>
                            </SocialsGrid>
                        </Box>
                    </Box>
                    {!isMobile && mainText}
                </Box>
                <Box grid dc={"1fr 1fr"} justifyContent={"flex-end"} padding={isMobile ? "0px" : "10px"}>
                    {!isMobile && copyright}
                    <Box display={"flex"} justifyContent={isMobile ? "center" : "flex-end"} paddingTop={isMobile ? "0px" : "20px"} fontWeight={700}>
                        <Box pr={2}>
                            <a target={"_blank"} href={"/terms"}>
                                <Label fontStyle={"h4"} color={"white"}>
                                    <strong>{"Terms of use"}</strong>
                                </Label>
                            </a>
                        </Box>
                        <a target={"_blank"} href={"/privacy"}>
                            <Label fontStyle={"h4"} color={"white"}>
                                <strong>Privacy Policy</strong>
                            </Label>
                        </a>
                    </Box>
                    {isMobile && copyright}
                </Box>
            </Box>
        </Main>
    );
};
