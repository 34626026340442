import styled from "@emotion/styled";
import { theme } from "Styles";
import { AssistantCommon } from "../Assistant-style";

export const EndStepWrapper = styled(AssistantCommon)`
    justify-content: center;
    margin-bottom: 40px;
`;

export const EndStep = styled.div`
    background-color: rgba(255, 255, 255, 0.1);
    width: 100%;

    min-height: 268px;

    margin-bottom: 400px;
    padding: 12px 20px;
    margin-top: 40px;
`;

export const EndStepTop = styled.div`
    border-bottom: 1px dashed ${theme.palette.common.white};
    padding-bottom: 15px;
`;

export const EndStepBottom = styled.div`
    margin-top: 20px;
`;
