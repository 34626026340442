import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { AssistantViewModel } from "../AssistantViewModel";
import { AssistantStepView, PreviousNext } from "../Assistant-style";
import { AssistantSteps } from "../AssistantSteps";
import { NextStepButton, PrevStepButton } from "Components/Buttons";
import stepIcon from "Assets/step02.svg";
import stepIconTablet from "Assets/step02tablet.svg";
import { ClientDetails, ClientInfo, EndWrapperWrapper } from "./EndUserStepView-style";
import { EndUserStepViewModel } from "./EndUserStepViewModel";
import { Property } from "Models";
import { formatAddress } from "Utils/format";
import { EditSelect, ErrorMessageView, Label } from "Application";
import Autocomplete from "react-google-autocomplete";
import { CoreStoreInstance, isNullOrEmpty } from "@shoothill/core";
import { ErrorBox } from "Components/Primitives/CommonStyles";

//const apikey = "AIzaSyB2GneTSnHumUPLPp7P6wS_hcGC7sGl67A";
const apikey = "AIzaSyAxSjp70d85VFkhetYSYwTS2IGdt8ZIST8";

export interface IStepEndUser {
    parentViewModel: AssistantViewModel;
}

export const EndUserStepView: React.FC<IStepEndUser> = observer(({ parentViewModel }) => {
    const [viewModel] = useState<EndUserStepViewModel>(parentViewModel.endUserViewModel);

    // Scrolling to top

    const scrollRef = useRef<any>("Jeff");
    const executeScroll = () => scrollRef.current.scrollTop();

    useEffect(() => {
        return () => {};
    }, []);

    CoreStoreInstance.isInfoBarOpen;

    useEffect(() => {
        if (viewModel.isModelValid(true, true) === true) {
            CoreStoreInstance.HideInfoBar();
        }
    }, [viewModel.isModelValid(true, true)]);

    const onPrev = (step: AssistantSteps) => {
        // We can go back regardless of state of the local viewModel
        parentViewModel.setStep(step);
    };

    const onNext = (step: AssistantSteps) => {
        // TODO Need to only do this if the end user viewModel is valid
        viewModel.setValue("firstValidate", false);

        if (viewModel.isModelValid(true, true) === true) {
            parentViewModel.setStep(step);
        }
        // parentViewModel.setStep(step);
    };

    const allowNextStep: boolean = true; // TODO Need to only do this if the end user viewModel is valid

    return (
        <>
            <EndWrapperWrapper padding={viewModel.isMobile ? "0 20px 0 20px" : "0px"}>
                <AssistantStepView>
                    <img className="icon" width={"100%"} src={viewModel.isMobile ? stepIcon : stepIconTablet} alt="end user step" />
                </AssistantStepView>

                <div>
                    <p style={{ marginBottom: "10px" }}>SIM ID: {parentViewModel.model.simId}</p>
                </div>
                <Label fontStyle={"h1"} color={"headerText"}>
                    Step 2
                </Label>
                <Label fontStyle={"h2"} color={"headerText"} mb={"16px"}>
                    End user info
                </Label>

                <ClientInfo>{viewModel.model.clientName}</ClientInfo>

                <ClientDetails>
                    <EditSelect
                        noHeader={true}
                        displayName=""
                        placeholder="Please select a property"
                        options={viewModel.getPropertyOptions}
                        value={() => viewModel.getSelectedPropertyId}
                        command={viewModel.updateSelectedPropertyId}
                        validationMessage={() => viewModel.getError("selectedPropertyId")}
                    />
                    {/* <div>TODO Put property list into a select. Allow user to manually edit / geocode. </div> */}
                    <p>&nbsp;</p>

                    {viewModel.model.selectedPropertyId !== undefined && viewModel.model.selectedPropertyId.length === 0 ? (
                        <div>
                            <Label fontStyle="h3" color="headerText" mb="15px">
                                Enter project site address:
                            </Label>
                            <Autocomplete
                                apiKey={apikey}
                                className={"autoCompleteInput"}
                                onPlaceSelected={(place: google.maps.places.PlaceResult) => {
                                    viewModel.updateChosenProperty(place);
                                    if (place.geometry?.location) {
                                        // Start here!
                                        let chosenPlaceLat = place.geometry?.location.lat();
                                        let chosenPlaceLng = place.geometry?.location.lng();
                                        parentViewModel.model.latFromAddress = chosenPlaceLat;
                                        parentViewModel.model.lngFromAddress = chosenPlaceLng;
                                    }

                                    console.log(place);
                                }}
                                options={{
                                    types: ["address"],
                                    componentRestrictions: { country: "gb" },
                                }}
                                defaultValue=""
                            />
                            <ErrorBox>
                                <ErrorMessageView
                                    haveError={!isNullOrEmpty(viewModel.getError("selectedPropertyFromSearch"))}
                                    validationMessage={() => "Please enter a valid address"}
                                />
                            </ErrorBox>

                            <p>{viewModel.model.selectedPropertyFromSearch?.addressLine1}</p>
                            <p>{viewModel.model.selectedPropertyFromSearch?.city}</p>
                            <p>{viewModel.model.selectedPropertyFromSearch?.county}</p>
                            <p>{viewModel.model.selectedPropertyFromSearch?.postcode}</p>
                        </div>
                    ) : (
                        <>
                            <div>Property selected: </div>
                            <div>
                                {viewModel.model.clientProperties.map((property: Property) => {
                                    return property.id === viewModel.model.selectedPropertyId ? <div>{formatAddress(property, true)}</div> : null;
                                })}
                            </div>
                        </>
                    )}
                </ClientDetails>

                <PreviousNext>
                    <PrevStepButton disabled={false} step={AssistantSteps.Contractor} buttonText={"Previous step"} onClick={onPrev} boxShadow={"0pt 3pt 6pt #00000059"} />
                    <NextStepButton disabled={!allowNextStep} step={AssistantSteps.SensorLocation} buttonText={"Next step"} onClick={onNext} boxShadow={"0pt 3pt 6pt #00000059"} />
                </PreviousNext>
            </EndWrapperWrapper>
        </>
    );
});
