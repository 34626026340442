import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { QrReader } from "react-qr-reader";
import { AssistantViewModel } from "./AssistantViewModel";
import styled from "@emotion/styled";
import { Box, Label } from "Components";
import { NextStepButton, PrevButton } from "Components/Buttons";
import { AppUrls } from "AppUrls";

export interface IStepStart {
    parentViewModel: AssistantViewModel;
}

const StepStartWrapper = styled.div`
    width: 100%;
    margin-bottom: 30px;

    .back {
        margin: 0;
        padding-bottom: 15px;
    }

    .title {
        text-align: center;
    }

    .qrReaderWrapper {
        max-width: 100%;
        height: 100%;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;

        //Overlay outline params
        --top-bottom-overlay-position: 25%;
        --left-right-overlay-position: 25%;
        --bracket-length: 15%;
        --border-size-colour: 4px solid #f29d29;
        /* background-color: purple; */

        .camera-overlay-container {
            position: relative;
            width: 100%;
            height: auto;
            z-index: 10;
        }

        .top-left {
            position: absolute;
            border-left: var(--border-size-colour);
            border-top: var(--border-size-colour);
            top: var(--top-bottom-overlay-position);
            left: var(--left-right-overlay-position);
            /* padding: 20px; */
            width: var(--bracket-length);
            height: var(--bracket-length);
        }

        .top-right {
            position: absolute;
            border-right: var(--border-size-colour);
            border-top: var(--border-size-colour);
            top: var(--top-bottom-overlay-position);
            right: var(--left-right-overlay-position);
            /* padding: 20px; */
            width: var(--bracket-length);
            height: var(--bracket-length);
        }

        .bottom-left {
            position: absolute;
            border-left: var(--border-size-colour);
            border-bottom: var(--border-size-colour);
            bottom: var(--top-bottom-overlay-position);
            left: var(--left-right-overlay-position);
            /* padding: 20px; */
            width: var(--bracket-length);
            height: var(--bracket-length);
        }

        .bottom-right {
            position: absolute;
            border-right: var(--border-size-colour);
            border-bottom: var(--border-size-colour);
            bottom: var(--top-bottom-overlay-position);
            right: var(--left-right-overlay-position);
            /* padding: 20px; */
            width: var(--bracket-length);
            height: var(--bracket-length);
        }
    }

    .qrReader {
        min-width: 100%;
        /* video {
            max-height: 10px !important;
        } */
    }
`;

export const StepStartView: React.FC<IStepStart> = observer(({ parentViewModel }) => {
    const [data, setData] = useState("Please scan a QR Code");

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            setData("Please scan a QR Code");
        };
    }, []);

    const doSimonHack = async () => {
        // DEV
        //
        //const hack: string = "L5AL-nRIUzjCp2uRifuOKLE9oMSza_5ypC1roFe-HUwzq8BvouSKIgZvRPFuSktvFq8HWz9SZi0zHNil0EQoqX2FEQaU7Qb43UyAeazDCfE";
        // CI
        //        const hack: string = "7qdiaVn4t7YO7DPmMIbL7ccn0HJfnpwXhZPIns45WukC8MyEnuu9sJExwldNnXI9M2xrIjfHKF1VQF1kEX6MYyaQTMS9U4G34NGDwhWjQm0";
        //parentViewModel.setValue("qrCode", hack);
        //let p = await parentViewModel.processQRCodeCommand();
    };

    return (
        <>
            <StepStartWrapper>
                <div className="title">
                    <Label fontStyle="h2" color={"headerText"} style={{ marginTop: "20px" }}>
                        Scan QR code on sensor
                    </Label>
                </div>
                <div className="qrReaderWrapper">
                    <div className="camera-overlay-container">
                        <QrReader
                            onResult={async (result: any | null | undefined, error: Error | null | undefined) => {
                                if (result !== null && result !== undefined) {
                                    setData(result?.text);
                                    parentViewModel.setValue("qrCode", result?.text);
                                    let p = await parentViewModel.processQRCodeCommand();
                                }

                                if (!!error) {
                                    console.info(error);
                                }
                            }}
                            className="qrReader"
                            constraints={{ facingMode: "environment" }}
                        />

                        <div className="top-left"></div>
                        <div className="top-right"></div>
                        <div className="bottom-left"></div>
                        <div className="bottom-right"></div>
                    </div>
                </div>
                <Box className="back">
                    <PrevButton url={AppUrls.Client.Home} buttonText={"Cancel"} boxShadow={"0pt 3pt 6pt #00000059"} />
                </Box>
                {/*  
                <Box className="back">
                    <NextStepButton step={AssistantSteps.Contractor} buttonText={"Demo!"} onClick={doSimonHack} />
                </Box> */}
            </StepStartWrapper>
        </>
    );
});
