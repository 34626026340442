import { APIClient } from "./APIClient";

interface EndpointResult<TModel = any> {
    data: TModel;
    errors: string[];
    wasSuccessful: boolean;
}

interface IEndpointWithResponse {
    HandleResponseAsync?(response: any): Promise<any>;
}
interface IEndpointWithRequest {
    HandleRequestAsync(request: any): Promise<any>;
    HandleResponseAsync?(response: any): Promise<any>;
}

export enum Http {
    Get = "Get",
    Post = "Post",
    Put = "Put",
    Patch = "Patch",
    Delete = "Delete",
}

export class EmptyRequest {}
export class EmptyResponse {}

abstract class BaseEndPointCommon<TRequest, TResponse> implements IEndpointWithResponse {
    protected _verb: Http = Http.Get;
    protected _path: string = "";
    protected _expiryInSeconds: number = 0;
    protected _sendBearer: boolean = true;

    abstract HandleResponseAsync(response: TResponse | TResponse[]): Promise<any>;

    public get Path(): string {
        return this._path;
    }

    public get Verb(): Http {
        return this._verb;
    }

    public get GetSendBearer(): boolean {
        return this._sendBearer;
    }

    public SendBearer(value: boolean): void {
        this._sendBearer = value;
    }

    public get Expiry(): number {
        return this._expiryInSeconds;
    }

    protected path = (url: string): any => {
        this._path = url;
    };

    protected verb = (method: Http) => {
        this._verb = method;
    };

    protected cache = (expiryInSeconds: number): any => {
        this._expiryInSeconds = expiryInSeconds * 1000;
    };
}

abstract class BaseEndPoint<TRequest, TResponse> extends BaseEndPointCommon<TRequest, TResponse> {
    abstract HandleRequestAsync(model: any): Promise<TRequest>;
    constructor() {
        super();
    }
}

abstract class BaseEndPointWithoutRequest<TRequest, TResponse> extends BaseEndPointCommon<TRequest, TResponse> {
    constructor() {
        super();
    }
}

export abstract class Endpoint<TRequest, TResponse> extends BaseEndPoint<TRequest, TResponse> {}

export abstract class EndpointWithoutRequest<TResponse> extends BaseEndPointWithoutRequest<EmptyRequest, TResponse> {}
