import React, { useState, useEffect } from "react";
import { useTimeout } from "../Application/Hooks";
import { Label, Box } from "Application";

interface Props {
    component?: JSX.Element;
    delayBeforeShow?: number;
    loadingText?: string;
}

export const Loader = (props: Props) => {
    const [show, setShow] = useState(false);
    const { start, clear } = useTimeout(() => {
        setShow(true);
    }, props.delayBeforeShow);

    useEffect(() => {
        start();
        return () => {
            clear();
        };
    }, []);

    if (show) {
        return (
            <Box display={"flex"} flexDirection={"column"} alignItems="center" justifyContent="center" style={{ minHeight: "90vh" }}>
                <Box>{props.component}</Box>
                <Label fontStyle={"h2"}>{props.loadingText}</Label>
            </Box>
        );
    } else return <></>;
};

Loader.defaultProps = {
    delayBeforeShow: 0,
    loadingText: "Loading...",
    /*component: <CircularProgress />,*/
};
