import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export enum CameraPanel {
    Button = 1,
    Camera = 2,
    PhotoTaken = 3,
}

export interface File64 {
    //bytes: number[];
    base64StringFile: string;
    fileName: string;
    fileType: string;
}

export class PhotoModel extends ModelBase<PhotoModel> {
    public id: string = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
        });
    }
}

export class PhotoModelValidator extends Validator<PhotoModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
