import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

import cameraCross from "Assets/CameraCross.svg";
import cameraTick from "Assets/CameraTick.svg";
import CameraIcon from "Assets/CameraIcon.svg";
import { useDropzone } from "react-dropzone";
import { PhotoViewModel } from "./PhotoViewModel";
import { CameraPanel } from "./PhotoModel";
import { CameraContainer, CancelButton, CancelPhotoButtonsWrapper, GalleryWrapper, PhotoCaptureOptionsWrapper, TakenPhotoButtonsWrapper } from "./PhotoView.style";
import { Label } from "Application";
import { IRoofcareUploadFile } from "Models/IRoofcareUploadFile";
import { CropAndResizeResult } from "Models/CropResizeInfo";

interface IPhotoProps {
    photoGalleryArr: CropAndResizeResult[];
    addPhotos: (photo: IRoofcareUploadFile[]) => void;
    deletePhoto: (id: string) => void;
    setError: (errOne: string, errTwo: string) => void;
}

export const PhotoView: React.FC<IPhotoProps> = observer((props: IPhotoProps) => {
    const [viewModel] = useState<PhotoViewModel>(new PhotoViewModel(props.addPhotos, props.deletePhoto));
    const { photoGalleryArr } = props;

    useEffect(() => {
        return () => {};
    }, []);

    useEffect(() => {
        viewModel.setGallery(photoGalleryArr);
    }, [photoGalleryArr]);

    function MyDropzone() {
        const onDrop = useCallback((acceptedFiles: any[], rejectedFiles: any[]) => {
            // Process the files
            if (acceptedFiles.length > 0) {
                viewModel.addFiles(acceptedFiles);
            }
            if (rejectedFiles.length > 0) {
                if (rejectedFiles.length >= 3) {
                    props.setError("You can only upload two images at a time.", "");
                } else {
                    props.setError(`Failed to upload the ${rejectedFiles.length} files`, "If problems persist, please contact RoofCare.");
                }
            }
        }, []);
        const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
            onDrop,
            accept: {
                "image/jpeg": [],
                "image/png": [],
            },
            maxFiles: 2,
        });

        return (
            <>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Drop the files here ...</p>
                    ) : (
                        <Label fontStyle={"h3"} color={"headerText"} style={{ textDecoration: "underline", textUnderlineOffset: "3px", cursor: "pointer" }}>
                            Or select up to two images from your device
                        </Label>
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            <CameraContainer>
                {viewModel.cameraPanelChoice == CameraPanel.Camera && (
                    <>
                        <Camera
                            isFullscreen={false}
                            onTakePhoto={(newPhoto) => {
                                viewModel.handleTakePhoto(newPhoto);
                            }}
                        />
                        <CancelPhotoButtonsWrapper>
                            <CancelButton onClick={viewModel.cancelTakePhoto}> Cancel</CancelButton>
                        </CancelPhotoButtonsWrapper>
                    </>
                )}
                {viewModel.cameraPanelChoice == CameraPanel.Button && (
                    <>
                        <PhotoCaptureOptionsWrapper>
                            <div className="select-device-image-wrapper">
                                <div onClick={viewModel.toggleCameraPanel} className={"take-photo-button"}>
                                    <img src={CameraIcon}></img>
                                </div>{" "}
                            </div>
                            <div className="select-device-image-wrapper">
                                <MyDropzone />
                            </div>
                        </PhotoCaptureOptionsWrapper>
                    </>
                )}
                {viewModel.cameraPanelChoice == CameraPanel.PhotoTaken && (
                    <>
                        <img src={viewModel.takenPhoto} width="100%" />
                        <TakenPhotoButtonsWrapper>
                            <button className="camera-buttons" onClick={viewModel.confirmPhotoOk}>
                                <img src={cameraTick}></img>
                            </button>
                            <button className="camera-buttons" onClick={viewModel.cancelImage}>
                                <img src={cameraCross}></img>
                            </button>
                        </TakenPhotoButtonsWrapper>
                    </>
                )}
            </CameraContainer>
            <GalleryWrapper>
                {viewModel.photoGalleryArr &&
                    viewModel.photoGalleryArr.map((photo, index) => (
                        <>
                            <div key={index} className="gallery-image-wrapper">
                                <img src={photo.thumbnailURL} width="100%" />
                                <button key={index} onClick={() => viewModel.deleteGalleryPhoto(photo.tempId)} className="gallery-image-delete">
                                    <img src={cameraCross}></img>
                                </button>
                            </div>
                        </>
                    ))}
            </GalleryWrapper>
        </>
    );
});
