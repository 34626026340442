import React, { useState } from "react";
import styled from "@emotion/styled";
import { Box, Label } from "../../Components";
import { theme } from "../../Styles";
import { HeaderViewModel } from "./HeaderViewModel";
import { observer } from "mobx-react-lite";
import { container } from "tsyringe";
import { AccountStore } from "../../Stores/Domain";
import logo from "Assets/roofcarelogo.svg";
import telephone from "Assets/telephone.svg";
import { AppUrls } from "AppUrls";
import { NavLink } from "react-router-dom";

const HeaderBarWrapper = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: ${theme.palette.primary.main};
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const HeaderBar = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    //Tablet
    /* max-width: 390px; */
    height: 50px;
    z-index: 1;
    display: flex;
    justify-content: space-between;

    .logo {
        position: absolute;
        top: 0;
        left: -2px;
        width: 150px;
        height: 50px;
    }

    .telephonebox {
        display: flex;
        flex-direction: row;
        flex: 1 0 0;

        //Old value:
        /* max-width: 150px; */

        //New Value, to stop number wrapping:
        max-width: fit-content;

        // Else try:
        /* white-space: nowrap; */
        margin-right: 16px;
        align-items: center;

        .telephone {
            width: 17.24pt;
            height: 17.24pt;
            padding-right: 4px;
        }
        .telephonenumber {
            color: ${theme.palette.common.white};
        }
    }
`;
export const Header: React.FC = observer(() => {
    const accountStore = container.resolve(AccountStore);
    const [headerViewModel] = useState(() => new HeaderViewModel());

    return (
        <HeaderBarWrapper>
            <HeaderBar>
                <Box pl={3}>
                    <NavLink className="button" to={AppUrls.Client.Home}>
                        <img className="logo" src={logo} alt="Roofcare" />
                    </NavLink>
                </Box>
                <Box className="telephonebox">
                    <img className="telephone" src={telephone} alt="call 01298 875001 " />
                    <Label className="telephonenumber" fontStyle={"headerOptionLabel"}>
                        01298 875001
                    </Label>
                </Box>
            </HeaderBar>
        </HeaderBarWrapper>
    );
});
