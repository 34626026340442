import { makeObservable, action, runInAction, computed } from "mobx";
import { ApiResult, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ActivationInstructionModel, ActivationInstructionModelValidator } from "./ActivationInstructionModel";
import { IRoofcareUploadFile } from "Models/IRoofcareUploadFile";
import { CropAndResizeResult } from "Models/CropResizeInfo";
import { CropAndResizeEndpoint, CropAndResizeRequest } from "../Endpoints/CropAndResize";

export class ActivationInstructionsStepViewModel extends ViewModelBase<ActivationInstructionModel> {
    private cropAndResizeEndpoint: CropAndResizeEndpoint = new CropAndResizeEndpoint();
    public apiClient = new APIClient();

    constructor() {
        super(new ActivationInstructionModel());
        this.setValidator(new ActivationInstructionModelValidator());
        makeObservable(this, {
            addPhotos: action,
            addDrainagePhotos: action,
            clear: action,
            deletePhoto: action,
            deleteDrainagePhoto: action,
            getPhotos: computed,
            getDrainagePhotos: computed,
            isBusy: computed,
        });
    }

    public clear() {
        this.model.clear();
    }

    public updateLocationDescription: ICommand = new RelayCommand((value: string) => {
        this.setValue("installationNotes", value);
        console.log(this.getValue("installationNotes"));
    });

    public get getPhotos(): CropAndResizeResult[] {
        return this.model.photos.slice();
    }

    public get isBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    public addPhotos = async (photos: IRoofcareUploadFile[]): Promise<void> => {
        if (this.apiClient.IsBusy === false) {
            for (let i: number = 0; i < photos.length; i++) {
                let photo: IRoofcareUploadFile = photos[i];

                let request: CropAndResizeRequest = {
                    fileBase64: photo.base64String,
                    fileName: photo.file.name,
                    aspect: 100,
                    width: 100,
                    height: 100,
                    x: 0,
                    y: 0,
                    resizeImage: false,
                    longestSideLength: -1,
                    imageSubDirectory: "installation",
                    storeThumb: true,
                };

                await this.apiClient.sendAsync<CropAndResizeRequest, ApiResult<CropAndResizeResult>>(this.cropAndResizeEndpoint, request);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        let retVal: CropAndResizeResult = this.apiClient.Response();
                        this.model.photos.push(retVal);
                    });
                } else {
                    // TODO CMR Show Error Message
                }
            }
        }
    };

    public deletePhoto = (id: string): void => {
        let whatsLeft = this.model.photos.filter((a) => a.tempId !== id);
        this.model.photos.replace(whatsLeft);
    };

    public get getDrainagePhotos(): CropAndResizeResult[] {
        return this.model.drainagePhotos.slice();
    }

    public addDrainagePhotos = async (photos: IRoofcareUploadFile[]): Promise<void> => {
        if (this.apiClient.IsBusy === false) {
            for (let i: number = 0; i < photos.length; i++) {
                let photo: IRoofcareUploadFile = photos[i];

                let request: CropAndResizeRequest = {
                    fileBase64: photo.base64String,
                    fileName: photo.file.name,
                    aspect: 100,
                    width: 100,
                    height: 100,
                    x: 0,
                    y: 0,
                    resizeImage: false,
                    longestSideLength: -1,
                    imageSubDirectory: "installation",
                    storeThumb: true,
                };

                await this.apiClient.sendAsync<CropAndResizeRequest, ApiResult<CropAndResizeResult>>(this.cropAndResizeEndpoint, request);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        let retVal: CropAndResizeResult = this.apiClient.Response();
                        this.model.drainagePhotos.push(retVal);
                    });
                } else {
                    // TODO CMR Show Error Message
                    //this.IsErrored = true;
                    //this.setError()
                }
            }
        }
    };

    public deleteDrainagePhoto = (id: string): void => {
        let whatsLeft = this.model.drainagePhotos.filter((a) => a.tempId !== id);
        this.model.drainagePhotos.replace(whatsLeft);
    };
}
