import { ModelBase } from "@shoothill/core";
import { makeObservable, action, observable, IObservableArray } from "mobx";
import { Validator } from "Application/Validation";
import { Client, Property } from "Models";

export class EndUserStepModel extends ModelBase<EndUserStepModel> {
    public selectedPropertyId: string = "";
    public selectedPropertyFromSearch: Property | undefined = undefined;

    public clientId: string | undefined = undefined;
    public clientName: string = "";

    public firstValidate: boolean = true;

    public clientProperties: IObservableArray<Property> = observable([]);

    constructor() {
        super();
        makeObservable(this, {
            selectedPropertyId: observable,
            clientName: observable,
            selectedPropertyFromSearch: observable,
            clear: action,
            load: action,
            setSelectedProperty: action,
        });
    }

    public clear(): void {
        this.selectedPropertyId = "";
        this.selectedPropertyFromSearch = undefined;
        this.clientId = undefined;
        this.clientName = "";
        this.clientProperties.clear();
    }

    public load(client: Client, properties: Property[]): void {
        if (client !== null && client !== undefined) {
            this.clientId = client.id;
            this.clientName = client.clientName;
            this.clientProperties.replace(properties);
            if (properties.length > 0) {
                this.selectedPropertyId = properties[0].id!;
                this.setSelectedProperty(this.selectedPropertyId);
            }
        }
    }

    public setSelectedProperty(selectedPropertyId: string): void {
        this.selectedPropertyId = selectedPropertyId;
        if (selectedPropertyId.length === 0) {
            if (this.selectedPropertyFromSearch !== undefined && this.selectedPropertyFromSearch.id !== undefined) {
                // then it was previously from the selected list, so reset it
                this.selectedPropertyFromSearch = undefined;
            } // else it is from a search so leave it in this instance
        } else {
            this.selectedPropertyFromSearch = undefined;
            // find the property

            let found: Property | undefined = this.clientProperties.find((a) => a.id === selectedPropertyId);

            if (found !== undefined) {
                this.selectedPropertyFromSearch = found;
            }
        }
    }
}

export class EndUserStepModelValidator extends Validator<EndUserStepModel> {
    constructor() {
        super();
        this.ruleFor("selectedPropertyFromSearch")
            .notNull()
            .when((model) => model.firstValidate === false);
    }
}
