import { ModelBase } from "@shoothill/core";
import { makeObservable, action, observable } from "mobx";
import { Validator } from "Application/Validation";

export class SensorLocationStepModel extends ModelBase<SensorLocationStepModel> {
    public locationDescription: string = "";
    public firstValidate: boolean = true;

    constructor() {
        super();
        makeObservable(this, {
            locationDescription: observable,
            clear: action,
        });
    }

    public clear() {
        this.locationDescription = "";
    }
}

export class SensorLocationStepModelValidator extends Validator<SensorLocationStepModel> {
    constructor() {
        super();
        this.ruleFor("locationDescription")
            .notEmpty()
            .withMessage("Please enter a location description")
            .minLength(2)
            .maxLength(512)
            .when((model) => model.firstValidate === false);
    }
}
