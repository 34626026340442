export enum AssistantSteps {
    Start = 0,
    Contractor = 1,
    EndUser = 2,
    SensorLocation = 3,
    GutterDetails = 4,
    InstallHeight = 5,
    ActivationInstructions = 6,
    End = 7,
}
