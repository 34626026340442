import { ModelBase } from "@shoothill/core";
import { computed, makeObservable, action, observable } from "mobx";
import { Validator } from "Application/Validation";
import { DrainageType, RoofType, SideType } from "Models";

export class GutterDetailsStepModel extends ModelBase<GutterDetailsStepModel> {
    public roofType: RoofType = RoofType.Pitched;
    public drainageType: DrainageType = DrainageType.Gravity;

    public sideType: SideType = SideType.ThreeSideSym;

    public side1Angle: number = 0;
    public side1Length: number = 0;

    public bottomLength: number = 0;

    public side1Angle2: number = 0;
    public side1Length2: number = 0;

    public side2Angle: number = 0;
    public side2Length: number = 0;

    public side2Angle2: number = 0;
    public side2Length2: number = 0;

    public p1: number = 0;
    public p2: number = 0;

    public firstValidate: boolean = true;

    // Flat
    public maxHeight: number = 0;

    constructor() {
        super();
        makeObservable(this, {
            roofType: observable,
            drainageType: observable,
            maxHeight: observable,
            bottomLength: observable,
            side1Angle: observable,
            side1Length: observable,
            side2Angle: observable,
            side2Length: observable,
            sideType: observable,
            p1: observable,
            p2: observable,
            getRoofType: computed,
            clear: action,
        });
    }

    public clear() {
        this.roofType = RoofType.Pitched;
        this.drainageType = DrainageType.Gravity;

        this.sideType = SideType.ThreeSideSym;

        this.side1Angle = 0;
        this.side1Length = 0;

        this.bottomLength = 0;

        this.side1Angle2 = 0;
        this.side1Length2 = 0;

        this.side2Angle = 0;
        this.side2Length = 0;

        this.side2Angle2 = 0;
        this.side2Length2 = 0;

        this.p1 = 0;
        this.p2 = 0;
    }

    public get getRoofType(): RoofType {
        return this.roofType;
    }

    public get getDrainageType(): DrainageType {
        return this.drainageType;
    }
}

export class GutterDetailsStepModelValidator extends Validator<GutterDetailsStepModel> {
    constructor() {
        super();
        this.ruleFor("p1")
            .greaterThanOrEqualTo(1)
            .withMessage("P1 must be greater than 0")
            .lessThanOrEqualTo(1000)
            .when((model) => model.firstValidate === false && (model.drainageType === DrainageType.SingleSiphonic || model.drainageType === DrainageType.DualSiphonic));
        this.ruleFor("p2")
            .greaterThanOrEqualTo(1)
            .withMessage("P2 must be greater than 0")
            .lessThanOrEqualTo(1000)
            .when((model) => model.firstValidate === false && model.drainageType === DrainageType.DualSiphonic);

        this.ruleFor("bottomLength")
            .greaterThanOrEqualTo(1)
            .withMessage("Enter Length")
            .lessThanOrEqualTo(1000)
            .when((model) => model.firstValidate === false && model.roofType === RoofType.Pitched);

        this.ruleFor("side1Angle")
            .greaterThanOrEqualTo(1)
            .withMessage("Enter Angle")
            .lessThanOrEqualTo(360)
            .when((model) => model.firstValidate === false && model.roofType === RoofType.Pitched);
        this.ruleFor("side1Length")
            .greaterThanOrEqualTo(1)
            .withMessage("Enter Length")
            .lessThanOrEqualTo(1000)
            .when((model) => model.firstValidate === false && model.roofType === RoofType.Pitched);

        this.ruleFor("side1Angle2").greaterThanOrEqualTo(0).lessThanOrEqualTo(360);
        this.ruleFor("side1Length2").greaterThanOrEqualTo(0).lessThanOrEqualTo(1000);

        this.ruleFor("side2Angle")
            .greaterThanOrEqualTo(1)
            .withMessage("Enter Angle")
            .lessThanOrEqualTo(360)
            .when((model) => model.firstValidate === false && model.roofType === RoofType.Pitched);
        this.ruleFor("side2Length")
            .greaterThanOrEqualTo(1)
            .withMessage("Enter Length")
            .lessThanOrEqualTo(1000)
            .when((model) => model.firstValidate === false && model.roofType === RoofType.Pitched);

        this.ruleFor("side2Angle2").greaterThanOrEqualTo(0).lessThanOrEqualTo(360);
        this.ruleFor("side2Length2").greaterThanOrEqualTo(0).lessThanOrEqualTo(1000);

        // For flat roof only
        this.ruleFor("maxHeight")
            .greaterThanOrEqualTo(1)
            .withMessage("Height must be greater than 0")
            .lessThanOrEqualTo(1000)
            .when((model) => model.firstValidate === false && model.roofType === RoofType.Flat);

        // ******************

        this.ruleFor("sideType")
            .greaterThan(SideType.Unknown)
            .when((model) => model.firstValidate === false && model.roofType === RoofType.Pitched);

        this.ruleFor("drainageType").greaterThan(0);
    }
}
