import { Property } from "Models";

export const trimChar = (text: string, char: string) => {
    while (text.charAt(0) == char) {
        text = text.substring(1);
    }

    while (text.charAt(text.length - 1) == char) {
        text = text.substring(0, text.length - 1);
    }

    return text;
};

export const formatAddress = (address: Property, withPostcode: boolean = false) => {
    let formattedAddress = "";

    if (address.buildingName) formattedAddress += `  ${address.buildingName.trim()},`;

    if (address.addressLine1) formattedAddress += ` ${address.addressLine1.trim()},`;

    if (address.addressLine2) formattedAddress += ` ${address.addressLine2.trim()},`;

    if (address.city) formattedAddress += ` ${address.city.trim()},`;

    if (address.county) formattedAddress += ` ${address.county.trim()},`;

    if (withPostcode === true && address.postcode) formattedAddress += ` ${address.postcode.trim()},`;

    formattedAddress = trimChar(formattedAddress, ",").trim();

    return formattedAddress.replace(",, ", ", ");
};
