import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "../../../Application/Validation";
import { Guid } from "../../../Application";

// App
export class UserModel extends ModelBase<UserModel> {
    public id: Guid | null = null;
    public firstName: string = "";
    public lastName: string = "";
    public emailAddress: string = "";
    public password: string = "";
    public concurrencyToken: string = "";
    public lastLoginDate: Date | null = null;
    public userRoles = observable<string>([]);

    constructor(id: Guid | null) {
        super();
        this.id = id;
        makeObservable(this, {
            firstName: observable,
            lastName: observable,
            emailAddress: observable,
            password: observable,
            concurrencyToken: observable,
            lastLoginDate: observable,
            userRoles: observable,
        });
    }
}

export class UserModelValidator extends Validator<UserModel> {
    constructor() {
        super();
        this.ruleFor("emailAddress").emailAddress();
    }
}
