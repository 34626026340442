import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { CropAndResizeResult, CropResizeInfo } from "Models";
import moment from "moment";
import { ApiResult } from "@shoothill/core";

export interface CropAndResizeRequest extends CropResizeInfo {}

export interface CropAndResizeResponse extends CropAndResizeResult {}

export class CropAndResizeEndpoint extends Endpoint<CropAndResizeRequest, ApiResult<CropAndResizeResult>> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path("/api/Images/CropAndResize");
    }

    private base64MimeType(encoded: string) {
        let result = null;

        if (typeof encoded !== "string") {
            return result;
        }

        let mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

        if (mime && mime.length) {
            result = mime[1];
        }

        return result;
    }

    public async HandleRequestAsync(model: CropResizeInfo): Promise<any> {
        if (model.fileName === null || model.fileName === undefined || model.fileName.length === 0) {
            model.fileName = moment.utc().format("yyyy-MM-DDHH:mm:ss_SSSSSSSSS_dew.") + "_photo.";

            let mime = this.base64MimeType(model.fileBase64);

            if (mime !== null) {
                mime = mime.toLocaleUpperCase();
                if (mime.indexOf("JPG") !== -1) {
                    model.fileName += "jpg";
                } else if (mime.indexOf("JPEG") !== -1) {
                    model.fileName += "jpeg";
                } else if (mime.indexOf("PNG") !== -1) {
                    model.fileName += "png";
                }
            }
        }

        let request: CropAndResizeRequest = {
            storeThumb: true,
            fileBase64: model.fileBase64,
            fileName: model.fileName,
            aspect: 100,
            width: 100,
            height: 100,
            x: 0,
            y: 0,
            resizeImage: true,
            longestSideLength: -1,
            imageSubDirectory: "",
        };

        return request;
    }

    public async HandleResponseAsync(response: ApiResult<CropAndResizeResult>): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
