import { ModelBase } from "@shoothill/core";
import { action, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class InstallationModel extends ModelBase<InstallationModel> {
    public qrCode: string = "";

    public id?: number = undefined;
    public deviceDBId?: number = undefined;
    public projectId?: string = undefined;
    public simId: string = "";
    public latFromAddress: number = 0;
    public lngFromAddress: number = 0;
    public hasData: boolean = false;
    public draggedLat: number = 0;
    public draggedLng: number = 0;

    constructor() {
        super();
        makeObservable(this, {
            hasData: observable,
            id: observable,
            deviceDBId: observable,
            qrCode: observable,
            projectId: observable,
            simId: observable,
            latFromAddress: observable,
            lngFromAddress: observable,
            draggedLat: observable,
            draggedLng: observable,
            clear: action,
            setQRData: action,
        });
    }

    public clear() {
        this.hasData = false;
        this.qrCode = "";
        this.id = undefined;
        this.deviceDBId = undefined;
        this.projectId = undefined;
        this.simId = "";
        this.latFromAddress = 0;
        this.lngFromAddress = 0;
    }

    public setQRData(simId: string) {
        this.hasData = true;
        this.qrCode = "";
        this.id = undefined;
        this.deviceDBId = undefined;
        this.projectId = undefined;
        this.simId = simId;
        this.latFromAddress = 0;
        this.lngFromAddress = 0;
    }
}

export class InstallationModelValidator extends Validator<InstallationModel> {
    constructor() {
        super();
        this.ruleFor("qrCode").notEmpty();
    }
}
