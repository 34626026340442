import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Label, MaskedOptions } from "Application";
import { AssistantViewModel } from "../AssistantViewModel";
import { AssistantStepView, PreviousNext } from "../Assistant-style";
import { AssistantSteps } from "../AssistantSteps";
import { NextStepButton, PrevStepButton } from "Components/Buttons";
import stepIcon from "Assets/step05.svg";
import stepIconTablet from "Assets/step05tablet.svg";
import { InstallHeightWrapper, InstallHeightBox } from "./InstallHeightStepView-style";

import { RoofcareInput } from "Styles/BaseStyles";
import { DoubleSuffix } from "../Suffix";
import { InstallHeightViewModel } from "./InstallHeightViewModel";
import { CoreStoreInstance } from "@shoothill/core";

export interface IStepInstallHeight {
    parentViewModel: AssistantViewModel;
}

export const InstallHeightStepView: React.FC<IStepInstallHeight> = observer(({ parentViewModel }) => {
    const [viewModel] = useState<InstallHeightViewModel>(parentViewModel.installHeightViewModel);

    CoreStoreInstance.isInfoBarOpen;

    useEffect(() => {
        if (viewModel.isModelValid(true, true) === true) {
            CoreStoreInstance.HideInfoBar();
        }
    }, [viewModel.isModelValid(true, true)]);

    const onPrev = (step: AssistantSteps) => {
        // We can go back regardless of state of the local viewModel
        parentViewModel.setStep(step);
    };

    const onNext = (step: AssistantSteps) => {
        viewModel.setValue("firstValidate", false);

        if (viewModel.isModelValid(true, true) === true) {
            parentViewModel.setStep(step);
        }
    };

    const allowNextStep: boolean = true; // TODO Need to only do this if the local viewModel is valid

    return (
        <>
            <InstallHeightWrapper padding={viewModel.isMobile ? "0 20px 0 20px" : "0px"}>
                <AssistantStepView>
                    <img className="icon" width={"100%"} src={viewModel.isMobile ? stepIcon : stepIconTablet} alt="install height step" />
                </AssistantStepView>
                <div>
                    <p style={{ marginBottom: "10px" }}>SIM ID: {parentViewModel.model.simId}</p>
                </div>

                <Label fontStyle={"h1"} color={"headerText"}>
                    Step 5
                </Label>

                <Label fontStyle={"h2"} color={"headerText"} mb={"16px"}>
                    Install height info
                </Label>
            </InstallHeightWrapper>
            <InstallHeightBox>
                <div className={viewModel.isMobile ? "installheightimg" : "installheightimgtablet"}></div>

                <div className="installHeightData">
                    <div className="text">Measure install height from the base of the sensor to the floor of the gutter.</div>
                    <div className="data">
                        <RoofcareInput
                            type="number"
                            className="noClear"
                            displayClearButton={false}
                            displayName="iH Install Height"
                            value={() => viewModel.getValue("installHeight")}
                            command={viewModel.updateInstallHeight}
                            placeholder={""}
                            suffix={<DoubleSuffix className1="heightSuffix" text1="height" className2="mmSuffix" text2="mm" />}
                            validationMessage={() => viewModel.getError("installHeight")}
                            maskedOptions={MaskedOptions.NumbersOnly()}
                        />
                    </div>
                </div>
            </InstallHeightBox>
            <InstallHeightWrapper padding={viewModel.isMobile ? "0 20px 0 20px" : "0px"}>
                <PreviousNext>
                    <PrevStepButton disabled={false} step={AssistantSteps.GutterDetails} buttonText={"Previous step"} onClick={onPrev} boxShadow={"0pt 3pt 6pt #00000059"} />
                    <NextStepButton
                        disabled={!allowNextStep}
                        step={AssistantSteps.ActivationInstructions}
                        buttonText={"Next step"}
                        onClick={onNext}
                        boxShadow={"0pt 3pt 6pt #00000059"}
                    />
                </PreviousNext>
            </InstallHeightWrapper>
        </>
    );
});
