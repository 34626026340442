import styled from "@emotion/styled";
import React, { useState } from "react";
import roofwide from "Assets/RoofWide.png";
import { Box, Label } from "Components";
import { AppUrls } from "AppUrls";
import { theme } from "Styles";
import { RoofcareButton } from "Components/Buttons";
import { HomeWrapper } from "./Home.styles";
import { HomeViewViewModel } from "./HomeViewViewModel";
import { observer } from "mobx-react-lite";

export const HomeView: React.FC = observer(({}) => {
    const textColor: string = theme.palette.common.white;
    const [viewModel] = useState<HomeViewViewModel>(new HomeViewViewModel());

    return (
        <>
            <HomeWrapper width={"100%"}>
                <Box className="photo">
                    <img className="logo" src={roofwide} alt="Roofcare" />
                </Box>
            </HomeWrapper>

            <HomeWrapper>
                <Box className="titlesection">
                    <Label fontStyle={"h1"} color={"headerText"} style={{ textAlign: "center" }}>
                        RoofCare
                    </Label>
                    <Label fontStyle={"h2"} color={"headerText"} style={{ textAlign: "center" }}>
                        Installation assistant
                    </Label>
                </Box>
                <Box className="bigbluebutton">
                    <RoofcareButton
                        url={AppUrls.Client.Assistant.Start}
                        buttonText={"Go!"}
                        textColor={textColor}
                        backgroundColor={"rgba(74, 171, 255, 1)"}
                        borderRadius={"85px"}
                        maxWidth={"170px"}
                        height={"170px"}
                        fontSize={"66px"}
                        boxShadow={"0pt 3pt 6pt #00000059"}
                    />
                </Box>
                <Box className="instructions">
                    <RoofcareButton
                        url={AppUrls.Client.Instructions}
                        buttonText={"View your roof type installation instructions"}
                        textColor={textColor}
                        backgroundColor={"rgba(135, 135, 135, 1)"}
                        borderRadius={"47px"}
                        maxWidth={"330px"}
                        height={"50px"}
                        boxShadow={"0pt 3pt 6pt #00000059"}
                    />
                </Box>
            </HomeWrapper>
        </>
    );
});
