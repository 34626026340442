import { makeObservable } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { InstallHeightModel, InstallHeightModelValidator } from "./InstallHeightModel";

export class InstallHeightViewModel extends ViewModelBase<InstallHeightModel> {
    public apiClient = new APIClient();

    constructor() {
        super(new InstallHeightModel());
        this.setValidator(new InstallHeightModelValidator());
        makeObservable(this, {});
    }

    public clear() {
        this.model.clear();
    }

    private updateField(fieldName: keyof FieldType<InstallHeightModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public updateInstallHeight: ICommand = new RelayCommand((value: string) => {
        this.updateField("installHeight", parseInt(value));
    });
}
