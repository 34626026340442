import React from "react";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { Footer } from "../Footer/FooterView";
import { InfoBar } from "../../Components/InfoBar";
import { Outlet } from "react-router";
import { Box } from "../../Components";
import { mergeStyleSets } from "@fluentui/react";
import { pxToRem } from "../../Application";
import laptop from "Assets/laptop.jpg";
import { observer } from "mobx-react-lite";
import { Header } from "../Header/Header";

export const styles = mergeStyleSets({
    root: {
        display: "flex",
        height: "100%",
    },
    content: {
        boxShadow: `3px 0px 6px #00000029`,
        display: "flex",
        flex: "1.66",
        overflow: "hidden",
    },
    image: {
        backgroundImage: `url(${laptop})`,
        backgroundPosition: "left center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        flex: "1",
    },
});
//EN: use empty generic type to get default react props
export const SplitLayout: React.FC<{}> = observer((props) => {
    if (!props) return <>Props undefined</>;
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <>
                    <Header />
                    {/*EN: Make sure you apply overflowContainer classname to all your body content*/}
                    <Box className={"overflowContainer"}>
                        <InfoBar />
                        <Box className={styles.root}>
                            <Box className={styles.content}>
                                <Outlet />
                            </Box>
                            <Box id={"laptop"} className={styles.image}></Box>
                        </Box>
                        <Footer />
                    </Box>
                </>
            )}
        </>
    );
});
