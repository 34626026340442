import { ModelBase } from "@shoothill/core";
import { makeObservable, action, observable } from "mobx";
import { Validator } from "Application/Validation";
import { Contractor } from "Models";

export class ContractorStepModel extends ModelBase<ContractorStepModel> {
    public firstValidate: boolean = true;

    public id: string | undefined = undefined;

    // take a backup of the contractor name when loaded
    public loadedContractorName: string | undefined = undefined;

    public contractorName: string | undefined = undefined;
    public contactFirstName: string = "";
    public contactSurname: string = "";
    public contactMobile: string = "";

    constructor() {
        super();
        makeObservable(this, {
            contractorName: observable,
            contactFirstName: observable,
            contactSurname: observable,
            contactMobile: observable,
            clear: action,
            load: action,
        });
    }

    public clear() {
        this.id = undefined;
        this.contractorName = "";
        this.contactFirstName = "";
        this.contactSurname = "";
        this.contactMobile = "";
    }

    public load(contractor: Contractor) {
        if (contractor !== null && contractor !== undefined) {
            this.contractorName = contractor.contractorName;
            this.loadedContractorName = contractor.contractorName;
            this.id = contractor.id;
        }
    }
}

export class ContractorStepModelValidator extends Validator<ContractorStepModel> {
    constructor() {
        super();
        this.ruleFor("contractorName")
            .notEmpty()
            .minLength(2)
            .maxLength(128)
            .when((model) => model.firstValidate === false);
        this.ruleFor("contactFirstName")
            .notEmpty()
            .withMessage("Please enter a first name")
            .minLength(2)
            .maxLength(50)
            .when((model) => model.firstValidate === false);
        this.ruleFor("contactSurname")
            .notEmpty()
            .withMessage("Please enter a last name")
            .minLength(2)
            .maxLength(50)
            .when((model) => model.firstValidate === false);
        this.ruleFor("contactMobile")
            .notEmpty()
            .withMessage("Please enter a mobile number")
            .maxLength(50)
            .mobileNumber()
            .when((model) => model.firstValidate === false);
    }
}
