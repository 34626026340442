import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { CropAndResizeResult, ImageType, InstallationCommissioned, InstallationCommissionImage } from "Models";
import { InstallationPhotos } from "Models/InstallationPhotos";
import { AssistantViewModel } from "../AssistantViewModel";
import moment from "moment";

export interface CompleteInstallationRequest {
    installationCommissioned: InstallationCommissioned;
    photos: InstallationPhotos;
}

export interface CompleteInstallationResponse {}

export class CompleteInstallationEndpoint extends Endpoint<CompleteInstallationRequest, CompleteInstallationResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Unit.CompleteInstallation);
    }

    public async HandleRequestAsync(vm: AssistantViewModel): Promise<any> {
        // Check to see if the contractor name has changed.  If it has then reset the contractor id
        // so the server adds a new Contractor
        if (vm.contractorViewModel.model.contractorName!.toLocaleLowerCase() !== vm.contractorViewModel.model.loadedContractorName?.toLocaleLowerCase()) {
            vm.contractorViewModel.model.id = undefined;
        }

        let request: CompleteInstallationRequest = {
            installationCommissioned: {
                id: vm.model.id,
                deviceDBId: vm.model.deviceDBId!,
                simId: vm.model.simId,
                projectId: vm.model.projectId!,
                clientId: vm.endUserViewModel.model.clientId!,

                // Step 1 Contractor
                contractorId: vm.contractorViewModel.model.id!,
                contractorName: vm.contractorViewModel.model.contractorName!,
                installerFirstName: vm.contractorViewModel.model.contactFirstName!,
                installerLastName: vm.contractorViewModel.model.contactSurname,
                activationMobile: vm.contractorViewModel.model.contactMobile,
                // Step 2 End user / client
                propertyId: vm.endUserViewModel.model.selectedPropertyId.length > 0 ? vm.endUserViewModel.model.selectedPropertyId : undefined,
                buildingName: vm.endUserViewModel.model.selectedPropertyFromSearch?.buildingName,
                addressLine1: vm.endUserViewModel.model.selectedPropertyFromSearch?.addressLine1!,
                addressLine2: vm.endUserViewModel.model.selectedPropertyFromSearch?.addressLine2,
                city: vm.endUserViewModel.model.selectedPropertyFromSearch?.city!,
                county: vm.endUserViewModel.model.selectedPropertyFromSearch?.county!,
                postcode: vm.endUserViewModel.model.selectedPropertyFromSearch?.postcode!,
                propertyLatitude: vm.endUserViewModel.model.selectedPropertyFromSearch?.latitude!,
                propertyLongitude: vm.endUserViewModel.model.selectedPropertyFromSearch?.longitude!,

                // Step 3 Sensor Location
                deviceLatitude: vm.model.draggedLat,
                deviceLongitude: vm.model.draggedLng,
                sensorLocationDescription: vm.sensorLocationViewModel.model.locationDescription,

                // Step 4 Gutter Details
                roofType: vm.gutterDetailsViewModel.model.roofType,
                sideType: vm.gutterDetailsViewModel.model.sideType,
                drainageType: vm.gutterDetailsViewModel.model.drainageType,
                side1_Length: vm.gutterDetailsViewModel.model.side1Length,
                side1_Angle: vm.gutterDetailsViewModel.model.side1Angle,
                side2_Length: vm.gutterDetailsViewModel.model.side2Length,
                side2_Angle: vm.gutterDetailsViewModel.model.side2Angle,
                maxHeight: vm.gutterDetailsViewModel.model.maxHeight,
                p1: vm.gutterDetailsViewModel.model.p1,
                p2: vm.gutterDetailsViewModel.model.p2,
                baseMeasurement: vm.gutterDetailsViewModel.model.bottomLength,
                commissionDate: moment.utc().toISOString(),
                side1_Length2: vm.gutterDetailsViewModel.model.side1Length2,
                side1_Angle2: vm.gutterDetailsViewModel.model.side1Angle2,
                side2_Length2: vm.gutterDetailsViewModel.model.side2Length2,
                side2_Angle2: vm.gutterDetailsViewModel.model.side2Angle2,
                baseWidth: vm.gutterDetailsViewModel.model.bottomLength,

                // Step 5 Install Height
                install_Height: vm.installHeightViewModel.model.installHeight,

                // Step 6 Notes & photos (photos in a seperate object)
                installNotes: vm.activationInstructionViewModel.model.installationNotes,

                side1_Height: vm.gutterDetailsViewModel.side1_Height,
                side2_Height: vm.gutterDetailsViewModel.side2_Height,
            },
            photos: {
                newPhotos: vm.activationInstructionViewModel.model.photos.map((item: CropAndResizeResult) => {
                    let newPhoto: InstallationCommissionImage = {
                        id: -1,
                        installationCommissionedId: -1,
                        imageUrl: item.croppedAndResizedResult,
                        originalImageUrl: item.originalURL,
                        thumbnailImageUrl: item.thumbnailURL,
                        ordinal: 0,
                        isDeleted: false,
                        createdOn: moment.utc().toISOString(),
                        imageTypeId: ImageType.General,
                    };

                    return newPhoto;
                }),
                newDrainagePhotos: vm.activationInstructionViewModel.model.drainagePhotos.map((item: CropAndResizeResult) => {
                    let newDPhoto: InstallationCommissionImage = {
                        id: -1,
                        installationCommissionedId: -1,
                        imageUrl: item.croppedAndResizedResult,
                        originalImageUrl: item.originalURL,
                        thumbnailImageUrl: item.thumbnailURL,
                        ordinal: 0,
                        isDeleted: false,
                        createdOn: moment.utc().toISOString(),
                        imageTypeId: ImageType.Drainage,
                    };

                    return newDPhoto;
                }),
            },
        };
        return request;
    }

    public async HandleResponseAsync(response: CompleteInstallationResponse): Promise<CompleteInstallationResponse> {
        //Logger.logInformation("Response", response);
        return response;
    }
}
