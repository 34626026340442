import styled from "@emotion/styled";
import { theme } from "Styles";
import { AssistantCommon } from "../Assistant-style";

export const ActivationInstructionsWrapper = styled(AssistantCommon)`
    margin-bottom: 30px;
    width: auto;

    .instructionrow,
    .finalinstructionrow,
    .instructionrownodash {
        display: flex;
        flex: 1 0 0;
        flex-direction: row;
    }

    .instructionrow {
        border-bottom: 2px dashed ${theme.palette.common.white};
        margin-bottom: 5px;
    }

    .instructionSpacing {
        padding-bottom: 10px;
    }

    .instructionrowwithimage {
        border-bottom: 2px dashed ${theme.palette.common.white};
        margin-bottom: 10px;
    }

    .darker-button {
        background-color: #337ab7;
    }

    .finalinstructionrow {
        border-bottom: 1px solid ${theme.palette.common.white};

        padding-bottom: 15px;
        margin-bottom: 15px;
    }

    .number {
        min-width: 20px;
        width: 20px;
    }

    .drainagephotos {
        margin-top: 15px;
    }

    .drainagephotos {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px dashed ${theme.palette.common.white};
        margin-bottom: 20px;
    }
`;
