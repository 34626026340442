import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import installIcon from "Assets/installIcon.svg";
import { Box, Label } from "Components";
import { AppUrls } from "AppUrls";
import { PrevButton } from "Components/Buttons";
import { InstructionModal } from "./InstructionModal";

const InstructionsWrapper = styled.div`
    width: auto;

    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    .titlesection {
        margin-top: 30px;
        max-width: 512px;
        width: 100%;

        .title {
            line-height: 32px;
        }
    }

    .row {
        width: 100%;
        display: flex;
        flex: 1 0 0;
        flex-direction: row;
        margin-bottom: 10px;
        justify-content: space-between;
        /* background-color: green; */
    }

    .back {
        margin-top: 300px;
        margin-bottom: 44px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
`;

const InstructionsCell = styled.div`
    width: 33%;
    height: 110px;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    border: 2px solid white;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    background-color: rgba(255, 255, 255, 0.15);

    cursor: default;

    .icon {
    }

    :hover {
        cursor: pointer;
        border: 2px solid lightgray;

        .icon {
            svg {
                fill: green;
            }
        }
    }

    :first-child {
        margin-right: 10px;
        /*         background-color: yellow; */
    }

    :last-child {
        margin-left: 10px;
        /* background-color: pink; */
    }

    .instruction {
        margin-top: 11px;
        text-align: center;
        line-height: 14px;
    }
`;

export const InstructionsView: React.FC = () => {
    const [modalState, setModalState] = useState({
        isOpen: false,
        title: "",
        pdf: "",
    });

    const onClose = (): void => {
        setModalState({ isOpen: false, title: "", pdf: "" });
    };

    return (
        <>
            <InstructionModal open={modalState.isOpen} title={modalState.title} pdfURL={modalState.pdf} onClose={onClose} />

            <InstructionsWrapper>
                <Box className="titlesection">
                    <Label fontStyle="h1" color="headerText">
                        Roofcare
                    </Label>
                    <Label fontStyle="h1" color="headerText">
                        Installation Assistant
                    </Label>
                    <Label fontStyle="h3" color="headerText">
                        Roof type installation instructions
                    </Label>
                </Box>
                <Box mt={"32px"} maxWidth={"512px"} width="100%">
                    <div className="row">
                        <InstructionsCell
                            onClick={() =>
                                setModalState({
                                    isOpen: true,
                                    title: "Asbestos roof",
                                    pdf: "Roofcare Installation Instructions Asbestos Roof_02_22.pdf",
                                })
                            }
                        >
                            <div>
                                <img className="icon" src={installIcon} alt="Roofcare" />
                            </div>
                            <p className="instruction">Asbestos</p>
                        </InstructionsCell>
                        <InstructionsCell onClick={() => setModalState({ isOpen: true, title: "Flat roof", pdf: "Roofcare Installation Instructions Flat Roof_02_22.pdf" })}>
                            <div>
                                <img className="icon" src={installIcon} alt="Roofcare" />
                            </div>
                            <p className="instruction">Flat</p>
                        </InstructionsCell>
                        <InstructionsCell onClick={() => setModalState({ isOpen: true, title: "Metal roof", pdf: "Roofcare Installation Instructions Metal Roof_02_22.pdf" })}>
                            <div>
                                <img className="icon" src={installIcon} alt="Roofcare" />
                            </div>
                            <p className="instruction">Metal</p>
                        </InstructionsCell>
                    </div>
                    <div className="row">
                        <InstructionsCell onClick={() => setModalState({ isOpen: true, title: "Slate roof", pdf: "Roofcare Installation Instructions Slate Roof RCB_02_22.pdf" })}>
                            <div>
                                <img className="icon" src={installIcon} alt="Roofcare" />
                            </div>
                            <p className="instruction">Slate</p>
                        </InstructionsCell>
                        <InstructionsCell
                            onClick={() => setModalState({ isOpen: true, title: "Standing seam", pdf: "Roofcare Installation Instructions Standing Seam Roof_02_22.pdf" })}
                        >
                            <div>
                                <img className="icon" src={installIcon} alt="Roofcare" />
                            </div>
                            <p className="instruction">Standing seam</p>
                        </InstructionsCell>
                        <InstructionsCell
                            onClick={() => setModalState({ isOpen: true, title: "Tile roof", pdf: "Roofcare Installation Instructions Tile Roof RCA2_02_22_interactive.pdf" })}
                        >
                            <div>
                                <img className="icon" src={installIcon} alt="Roofcare" />
                            </div>
                            <p className="instruction">Tile</p>
                        </InstructionsCell>
                    </div>

                    <Box className="back">
                        <PrevButton url={AppUrls.Client.Home} buttonText={"Back"} />
                    </Box>
                </Box>
            </InstructionsWrapper>
        </>
    );
};
