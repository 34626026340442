import { Modal } from "@fluentui/react";
import { useState } from "react";
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styled from "@emotion/styled";
import installIcon from "Assets/installIcon.svg";
import { theme } from "Styles";
export interface IInstructionModal {
    open: boolean;
    pdfURL: string;
    title: string;
    onClose: () => void;
}

interface NumPages {
    numPages: any;
}

const ModalHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    justify-content: left;
    height: 48px;
    background-color: ${theme.palette.common.roofcareMain};
    color: ${theme.palette.common.white};
    .icon {
        max-width: 42px;
        padding: 12px;

        img {
            max-width: 19px;
        }

        /* background-color: green; */
    }

    .title {
        padding: 8px;
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        line-height: 14px;
    }

    .close {
        padding: 12px;
        font-size: 14px;
        max-width: 42px;
    }
`;

export const InstructionModal: React.FC<IInstructionModal> = ({ open, pdfURL, title, onClose }) => {
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const [allPages, setPages] = useState<number[]>([]);

    //pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

    //pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    function onDocumentLoadSuccess(value: NumPages) {
        setNumPages(value.numPages);

        let retVal: number[] = [];

        for (let i: number = 1; i <= value.numPages; i++) {
            retVal.push(i);
        }
        setPages(retVal);
    }

    function onDocumentLoadError(value: any) {
        alert(value);
    }

    function prevPage() {
        let current: number = pageNumber;

        current -= 1;

        if (current < 1) {
            current = 1;
        }

        setPageNumber(current);
    }

    function nextPage() {
        let current: number = pageNumber;

        current += 1;

        if (current >= numPages) {
            current = numPages;
        }

        setPageNumber(current);
    }

    return (
        <>
            <Modal containerClassName={"modalcontainer"} className={"instructionModal"} isOpen={open} onDismiss={() => onClose} isBlocking={false}>
                <ModalHeader>
                    <div className="icon">
                        <img src={installIcon} alt="Roofcare" />
                    </div>
                    <div className="title">
                        <p>{title}</p>
                        <p>installation instructions</p>
                    </div>
                    <div className="close" onClick={() => onClose()}>
                        <p>X</p>
                    </div>
                </ModalHeader>
                <div className="modalinstruction">
                    {pdfURL.length > 0 && (
                        <Document file={pdfURL} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                            {allPages.map((page) => (
                                <Page
                                    pageNumber={page}
                                    width={document.getElementsByClassName("PdfDiv")[0]?.clientWidth * 0.8 ?? 390}
                                    height={document.getElementsByClassName("PdfDiv")[0]?.clientHeight * 0.8 ?? 150}
                                />
                            ))}
                        </Document>
                    )}
                </div>
                {/*                 {numPages > 0 && (
                    <div>
                        <div onClick={() => prevPage()}>Prev Page</div>
                        <div onClick={() => nextPage()}>Next Page</div>
                    </div>
                )} */}
            </Modal>
        </>
    );
};
