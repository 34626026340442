import React, { useEffect, useState } from "react";
import { AssistantViewModel } from "./AssistantViewModel";
import { observer } from "mobx-react-lite";
import { StepStartView } from "./StepStartView";
import { ContractorStepView } from "./ContractorStep/ContractorStepView";
import { EndUserStepView } from "./EndUserStep/EndUserStepView";
import { SensorLocationStepView } from "./SensorLocationStep/SensorLocationStepView";
import { GutterDetailsStepView } from "./GutterDetailsStep/GutterDetailsStepView";
import { InstallHeightStepView } from "./InstallHeightStep/InstallHeightStepView";
import { ActivationInstructionsStepView } from "./ActivationInstructionStep/ActivationInstructionsStepView";
import { EndStepView } from "./EndStep/EndStepView";
import { AssistantWrapper } from "./AssistantView.style";
import { LoaderBase } from "Components/Primitives/Loaders/LoaderBase";
import { ErrorModal } from "./ErrorModal";

export enum AssistantSteps {
    Start = 0,
    Contractor = 1,
    EndUser = 2,
    SensorLocation = 3,
    GutterDetails = 4,
    InstallHeight = 5,
    ActivationInstructions = 6,
    End = 7,
}

export const AssistantView: React.FC = observer(() => {
    const [viewModel] = useState<AssistantViewModel>(new AssistantViewModel());
    const [currentStep, setCurrentStep] = useState<AssistantSteps>(AssistantSteps.Start);
    const [showPrompt, setShowPrompt] = useState<boolean>(false);

    //Error Modal
    const [modalState, setModalState] = useState({
        isOpen: false,
        title: "",
        message1: "",
        message2: "",
    });

    const onClose = (): void => {
        setModalState({ isOpen: false, title: "", message1: "", message2: "" });
        viewModel.clearError();
    };

    useEffect(() => {
        return () => {
            viewModel.clear();
            setCurrentStep(AssistantSteps.Start);
        };
    }, []);

    useEffect(() => {
        setCurrentStep(viewModel.assistantStep);
    }, [viewModel.assistantStep]);

    useEffect(() => {
        setModalState({ isOpen: viewModel.isInError, title: "Error", message1: viewModel.errorMessage1, message2: viewModel.errorMessage2 });
    }, [viewModel.isInError]);

    useEffect(() => {
        setShowPrompt(viewModel.isBlocking);
    }, [viewModel.isBlocking]);

    return (
        <>
            <ErrorModal open={modalState.isOpen} title={modalState.title} message1={modalState.message1} message2={modalState.message2} onClose={onClose}></ErrorModal>

            {viewModel.apiClient.IsBusy === true && <LoaderBase isOpen={viewModel.apiClient.IsBusy} className="loadingscreen" displayText="Loading" />}
            {viewModel.apiClient.IsBusy === false && (
                <AssistantWrapper>
                    {currentStep === AssistantSteps.Start && <StepStartView parentViewModel={viewModel} />}
                    {currentStep === AssistantSteps.Contractor && <ContractorStepView parentViewModel={viewModel} />}
                    {currentStep === AssistantSteps.EndUser && <EndUserStepView parentViewModel={viewModel} />}
                    {currentStep === AssistantSteps.SensorLocation && <SensorLocationStepView parentViewModel={viewModel} />}
                    {currentStep === AssistantSteps.GutterDetails && <GutterDetailsStepView parentViewModel={viewModel} />}
                    {currentStep === AssistantSteps.InstallHeight && <InstallHeightStepView parentViewModel={viewModel} />}
                    {currentStep === AssistantSteps.ActivationInstructions && <ActivationInstructionsStepView parentViewModel={viewModel} />}
                    {currentStep === AssistantSteps.End && <EndStepView parentViewModel={viewModel} />}
                </AssistantWrapper>
            )}
        </>
    );
});
