import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";

export interface ISingleSuffix {
    className: string;
    text: string;
}

export const SingleSuffix: React.FC<ISingleSuffix> = observer(({ className, text }) => {
    return <div className={className}>{text}</div>;
});

export interface IDoubleSuffix {
    className1: string;
    className2: string;
    text1: string;
    text2: string;
}

export const DoubleSuffix: React.FC<IDoubleSuffix> = observer(({ className1, className2, text1, text2 }) => {
    return (
        <div className="dualsuffix">
            <div className={className1}>{text1}</div>;<div className={className2}>{text2}</div>;
        </div>
    );
});
