import styled from "@emotion/styled";

export const AssistantWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;

    .title {
        margin-top: 14px;
        margin-bottom: 11px;
    }

    .back {
        margin-top: 300px;
        margin-bottom: 44px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    text-decoration: none;
`;
