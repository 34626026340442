import { Rule } from "./Rule";
import validator from "validator";

export class MobileNumberRule<TModel, TValue> extends Rule<TModel, TValue> {
    constructor() {
        super((value: TValue) => {
            if (value == null) {
                return null;
            }
            if (typeof value !== "string") {
                throw new TypeError("Not a valid mobile number");
            }
            return validator.isMobilePhone(value, "en-GB") === true ? null : "Not a valid mobile number";
        });
    }
}
