import { CropAndResizeResult } from "Models/CropResizeInfo";
import { ModelBase } from "@shoothill/core";
import { makeObservable, action, observable, IObservableArray } from "mobx";
import { Validator } from "Application/Validation";

export class ActivationInstructionModel extends ModelBase<ActivationInstructionModel> {
    public installationNotes: string = "";

    public photos: IObservableArray<CropAndResizeResult> = observable([]);
    public drainagePhotos: IObservableArray<CropAndResizeResult> = observable([]);

    public firstValidate: boolean = true;

    constructor() {
        super();
        makeObservable(this, {
            installationNotes: observable,
            photos: observable,
            drainagePhotos: observable,
            clear: action,
        });
    }

    public clear() {}
}

export class ActivationInstructionModelValidator extends Validator<ActivationInstructionModel> {
    constructor() {
        super();
        this.ruleFor("installationNotes")
            .notEmpty()
            .withMessage("Please enter an installation note")
            .maxLength(2048)
            .when((model) => model.firstValidate === false);
    }
}
