import styled from "@emotion/styled";
import { AssistantCommon } from "../Assistant-style";

export const SensorLocationWrapper = styled(AssistantCommon)`
    width: auto;
    position: relative;
    margin-bottom: 30px;
`;

export const SensorLocationMap = styled(AssistantCommon)`
    /* position: relative; */
    /* margin-left: -20px; */
    background-color: #efefef;
    padding: 0;
    /* right: -10px; */
    /* left: 0; */
    min-width: 100%;
    min-height: 370px;
`;

export const SensorLocationDescription = styled.div`
    margin-bottom: 46px;
`;
