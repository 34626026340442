import { CropAndResizeResult } from "Models/CropResizeInfo";
import { action, IObservableArray, makeObservable, observable } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { CameraPanel, File64, PhotoModel, PhotoModelValidator } from "./PhotoModel";
import { Buffer } from "buffer";
import { IRoofcareUploadFile } from "Models/IRoofcareUploadFile";

export class PhotoViewModel extends ViewModelBase<PhotoModel> {
    public cameraPanelChoice: CameraPanel = CameraPanel.Button;
    public takenPhoto: string = "";

    public photoGalleryArr: IObservableArray<CropAndResizeResult> = observable([]);

    private addPhotos: (photo: IRoofcareUploadFile[]) => void;
    private deletePhoto: (id: string) => void;

    constructor(addPhotos: (photo: IRoofcareUploadFile[]) => void, deletePhoto: (id: string) => void) {
        super(new PhotoModel());
        this.setValidator(new PhotoModelValidator());
        makeObservable(this, {
            addFile: action,
            setGallery: action,
            cameraPanelChoice: observable,
            photoGalleryArr: observable,
            takenPhoto: observable,
            deleteGalleryPhoto: action,
            toggleCameraPanel: action,
            cancelImage: action,
            cancelTakePhoto: action,
            handleTakePhoto: action,
        });

        this.addPhotos = addPhotos;
        this.deletePhoto = deletePhoto;
    }

    public getImage = (photo: IRoofcareUploadFile): string | undefined => {
        return URL.createObjectURL(photo.file);
    };

    public setGallery = (gallery: CropAndResizeResult[]) => {
        this.photoGalleryArr.replace(gallery);
    };

    public addFiles = async (acceptedFiles: File[]) => {
        let newFiles: IRoofcareUploadFile[] = [];

        for (let i: number = 0; i < acceptedFiles.length; i++) {
            let file: File = acceptedFiles[i];

            await this.getFileTo64(file).then((result) => {
                let newFile: IRoofcareUploadFile = {
                    file: file,
                    base64String: result.base64StringFile,
                };
                newFiles.push(newFile);
            });
        }

        this.addPhotos(newFiles);
    };

    public addFile(file: File) {
        this.getFileTo64(file).then((result) => {
            let newFile: IRoofcareUploadFile = {
                file: file,
                base64String: result.base64StringFile,
            };
        });
    }

    public toggleCameraPanel = () => {
        this.cameraPanelChoice = CameraPanel.Camera;
    };

    public cancelImage = () => {
        this.cameraPanelChoice = CameraPanel.Camera;
    };

    public cancelTakePhoto = () => {
        this.cameraPanelChoice = CameraPanel.Button;
    };

    public handleTakePhoto = (newPhoto: string) => {
        this.takenPhoto = newPhoto;
        this.cameraPanelChoice = CameraPanel.PhotoTaken;
    };

    private dataUrlToFile = (dataUrl: string, filename: string): File | undefined => {
        const arr = dataUrl.split(",");
        if (arr.length < 2) {
            return undefined;
        }
        const mimeArr = arr[0].match(/:(.*?);/);
        if (!mimeArr || mimeArr.length < 2) {
            return undefined;
        }
        const mime = mimeArr[1];
        const buff = Buffer.from(arr[1], "base64");
        return new File([buff], filename, { type: mime });
    };

    private getFileTo64 = (file: File): Promise<File64> => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onerror = () => {
                reader.abort();
                reject(new Error("Error parsing file"));
            };
            reader.onloadend = function () {
                // use a regex to remove data url part
                const base64String: string = reader.result!.toString(); //.replace("data:", "").replace(/^.+,/, "");

                //Resolve the promise with your custom file structure
                let retVal: File64 = {
                    base64StringFile: base64String,
                    fileName: file.name,
                    fileType: file.type,
                };

                resolve(retVal);
            };
            reader.readAsDataURL(file);
        });
    };

    public confirmPhotoOk = () => {
        const image: string = this.takenPhoto;
        let newFile: File | undefined = this.dataUrlToFile(image, "");

        if (newFile !== undefined) {
            let file: IRoofcareUploadFile = {
                file: newFile,
                base64String: image,
            };

            this.addPhotos([file]);
        }

        this.cameraPanelChoice = CameraPanel.Button;
        this.takenPhoto = "";
    };

    public deleteGalleryPhoto = (id: string) => {
        this.deletePhoto(id);
    };
}
