import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Label } from "Application";
import { AssistantViewModel } from "../AssistantViewModel";
import { AssistantStepView, PreviousNext } from "../Assistant-style";
import { AssistantSteps } from "../AssistantSteps";
import { NextStepButton, PrevStepButton } from "Components/Buttons";
import stepIcon from "Assets/step03.svg";
import stepIconTablet from "Assets/step03tablet.svg";
import { SensorLocationDescription, SensorLocationMap, SensorLocationWrapper } from "./SensorLocationStepView-style";
import { RoofcareInput } from "Styles/BaseStyles";
import { SensorLocationStepViewModel } from "./SensorLocationStepViewModel";
import { CoreStoreInstance, generateID } from "@shoothill/core";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

export interface IStepSensorLocation {
    parentViewModel: AssistantViewModel;
}

export interface CoOrdinates {
    latLng: {
        lat: number;
        lng: number;
    };
}
// Customer API key. Get Simon to make sure urls are white listed
//
const apikey = "AIzaSyAxSjp70d85VFkhetYSYwTS2IGdt8ZIST8";

// From Erik
//const apikey = "AIzaSyB2GneTSnHumUPLPp7P6wS_hcGC7sGl67A";

export const SensorLocationStepView: React.FC<IStepSensorLocation> = observer(({ parentViewModel }) => {
    const [viewModel] = useState<SensorLocationStepViewModel>(parentViewModel.sensorLocationViewModel);
    const [isManual, setIsManual] = useState(false);
    const [mapId, setMapId] = useState<string>("");

    CoreStoreInstance.isInfoBarOpen;

    useEffect(() => {
        if (viewModel.isModelValid(true, true) === true) {
            CoreStoreInstance.HideInfoBar();
        }
    }, [viewModel.isModelValid(true, true)]);

    const onPrev = (step: AssistantSteps) => {
        // We can go back regardless of state of the local viewModel
        parentViewModel.setStep(step);
    };

    const onNext = (step: AssistantSteps) => {
        viewModel.setValue("firstValidate", false);

        if (viewModel.isModelValid(true, true) === true) {
            parentViewModel.setStep(step);
        }
    };

    const allowNextStep: boolean = true; // TODO Need to only do this if the local viewModel is valid

    // Scrolling to top

    const scrollRef = useRef<any>("Jeff");
    const executeScroll = () => scrollRef.current.scrollTop();

    //Useful Docs for \/ \/ \/ \/ - https://react-google-maps-api-docs.netlify.app/

    function GoogleMapRender(props: CoOrdinates) {
        const { isLoaded } = useLoadScript({ googleMapsApiKey: apikey });
        if (!isLoaded) return <div>Loading...</div>;
        return <Map latLng={props.latLng}></Map>;
    }

    function Map(props: CoOrdinates) {
        return (
            <>
                <GoogleMap
                    zoom={19}
                    mapTypeId={"satellite"}
                    tilt={0}
                    options={{ streetViewControl: false, mapTypeId: "satellite", keyboardShortcuts: false }}
                    center={
                        parentViewModel.model.draggedLat && parentViewModel.model.draggedLat
                            ? {
                                  lat: parentViewModel.model.draggedLat,
                                  lng: parentViewModel.model.draggedLng,
                              }
                            : props.latLng
                    }
                    mapContainerStyle={{ height: "370px", width: "100%" }}
                >
                    <Marker
                        position={
                            parentViewModel.model.draggedLat && parentViewModel.model.draggedLat
                                ? {
                                      lat: parentViewModel.model.draggedLat,
                                      lng: parentViewModel.model.draggedLng,
                                  }
                                : props.latLng
                        }
                        draggable={true}
                        onDragEnd={(e: any) => {
                            console.log(`Lat: ${e.latLng.lat()}  Lng: ${e.latLng.lng()}`);
                            viewModel.updateDraggedMarkerValues(e.latLng.lat(), e.latLng.lng());
                        }}
                    />
                </GoogleMap>
            </>
        );
    }

    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        //This gets run when the page is exited
        setMapId(generateID());
        setIsManual(true);

        return () => {
            setMapId("");
        };
    }, []);

    return (
        <>
            <SensorLocationWrapper padding={viewModel.isMobile ? "0 20px 0 20px" : "0px"}>
                <AssistantStepView>
                    <img className="icon" width={"100%"} src={viewModel.isMobile ? stepIcon : stepIconTablet} alt="sensor location step" />
                </AssistantStepView>

                <div>
                    <p style={{ marginBottom: "10px" }}>Sim Id: {parentViewModel.model.simId}</p>
                </div>

                <Label fontStyle={"h1"} color={"headerText"}>
                    Step 3
                </Label>
                <h2></h2>
                <Label fontStyle={"h2"} color={"headerText"} mb={"16px"}>
                    Sensor location
                </Label>
            </SensorLocationWrapper>

            <SensorLocationMap>
                <GoogleMapRender latLng={{ lat: parentViewModel.model.latFromAddress, lng: parentViewModel.model.lngFromAddress }}></GoogleMapRender>
            </SensorLocationMap>

            {/* **** Google Maps **** */}

            <SensorLocationWrapper padding={viewModel.isMobile ? "0 20px 0 20px" : "0px"}>
                <SensorLocationDescription>
                    <div style={{ marginTop: "15px" }}></div>
                    <RoofcareInput
                        displayName="Sensor location description"
                        placeholder="Please add a brief description of the sensor location"
                        multiline={true}
                        command={viewModel.updateLocationDescription}
                        value={() => viewModel.model.locationDescription}
                        validationMessage={() => viewModel.getError("locationDescription")}
                    />
                </SensorLocationDescription>

                <PreviousNext>
                    <PrevStepButton disabled={false} step={AssistantSteps.EndUser} buttonText={"Previous step"} onClick={onPrev} boxShadow={"0pt 3pt 6pt #00000059"} />
                    <NextStepButton disabled={!allowNextStep} step={AssistantSteps.GutterDetails} buttonText={"Next step"} onClick={onNext} boxShadow={"0pt 3pt 6pt #00000059"} />
                </PreviousNext>
            </SensorLocationWrapper>
        </>
    );
});
