import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { boxShadow } from "styled-system";
import { theme } from "Styles";
import { AssistantSteps } from "Views/Assistant/AssistantSteps";

interface IButtonBaseProps {
    textColor: string;
    backgroundColor: string;

    borderRadius: string;

    maxWidth: string;
    height: string;
    fontSize?: string;
    disabled?: boolean;
    boxShadow?: string;
}

interface IPrevNextButtonProps {
    step: AssistantSteps;
    buttonText: string;
    disabled?: boolean;
    boxShadow?: string;
    onClick: (step: AssistantSteps) => void;
}

interface IURLButtonProps {
    url: string;
    buttonText: string;
    boxShadow?: string;
}

interface INavButtonProps extends IButtonBaseProps, IURLButtonProps {}

interface IStepButtonProps extends IButtonBaseProps, IPrevNextButtonProps {}

const ButtonBase = styled.div<IButtonBaseProps>`
    text-decoration: none;
    width: ${(p) => (p.maxWidth ? p.maxWidth : "170px")};
    max-width: ${(p) => (p.maxWidth ? p.maxWidth : "170px")};
    height: ${(p) => (p.height ? p.height : "50px")};
    background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : theme.palette.common.primary)};
    border-radius: ${(p) => (p.borderRadius ? p.borderRadius : "47px")};
    position: relative;
    display: flex;
    flex: 1 0 0;
    justify-content: space-between;
    align-items: center;
    box-shadow: ${(p) => (p.boxShadow ? p.boxShadow : "none")};

    vertical-align: center;
    justify-content: center;
    align-content: center;

    :disabled,
    .disabled,
    &.disabled {
        background-color: "#efefe";
        pointer-events: none !important;
    }

    .buttonText {
        text-decoration: none !important;
        position: relative;
        font-size: ${(p) => (p.fontSize ? p.fontSize : "14px")};
        color: ${(p) => (p.textColor ? p.textColor : theme.palette.common.white)};

        &.disabled {
            background-color: "#efefe";
            pointer-events: none !important;
        }
    }

    cursor: default;

    :hover {
        cursor: pointer;
    }

    .instructionBtn {
        text-decoration: none !important;

        &.disabled {
            pointer-events: none;
        }
    }
`;

export const RoofcareButton = ({ url, boxShadow, buttonText, textColor, backgroundColor, borderRadius, maxWidth, height, fontSize, disabled }: INavButtonProps) => {
    const isDisabled: string = disabled !== undefined && disabled === true ? "disabled" : "";

    return (
        <ButtonBase
            className={isDisabled}
            textColor={textColor}
            backgroundColor={backgroundColor}
            borderRadius={borderRadius}
            maxWidth={maxWidth}
            height={height}
            fontSize={fontSize}
            disabled={disabled}
            boxShadow={boxShadow}
        >
            <NavLink className={"instructionBtn " + isDisabled} to={url}>
                <p className="buttonText">{buttonText}</p>
            </NavLink>
        </ButtonBase>
    );
};

export const RoofcareStepButton = ({ step, buttonText, boxShadow, textColor, backgroundColor, borderRadius, maxWidth, height, fontSize, onClick, disabled }: IStepButtonProps) => {
    return (
        <ButtonBase
            textColor={textColor}
            backgroundColor={backgroundColor}
            borderRadius={borderRadius}
            maxWidth={maxWidth}
            height={height}
            fontSize={fontSize}
            onClick={() => onClick(step)}
            disabled={disabled}
            boxShadow={boxShadow}
        >
            <p className="buttonText">{buttonText}</p>
        </ButtonBase>
    );
};

export const PrevButton = ({ url, buttonText, boxShadow }: IURLButtonProps) => {
    return (
        <RoofcareButton
            url={url}
            buttonText={buttonText}
            textColor={theme.palette.common.white}
            backgroundColor={theme.palette.common.prev}
            borderRadius={"47px"}
            maxWidth={"170px"}
            height={"50px"}
            boxShadow={boxShadow}
        />
    );
};

export const NextButton = ({ url, buttonText }: IURLButtonProps) => {
    return (
        <RoofcareButton
            url={url}
            buttonText={buttonText}
            textColor={theme.palette.common.white}
            backgroundColor={theme.palette.common.next}
            borderRadius={"47px"}
            maxWidth={"170px"}
            height={"50px"}
        />
    );
};

export const PrevStepButton = ({ step, buttonText, boxShadow, onClick, disabled }: IPrevNextButtonProps) => {
    return (
        <RoofcareStepButton
            step={step}
            buttonText={buttonText}
            textColor={theme.palette.common.white}
            backgroundColor={theme.palette.common.prev}
            borderRadius={"47px"}
            maxWidth={"170px"}
            height={"50px"}
            onClick={onClick}
            disabled={disabled}
            boxShadow={boxShadow}
        />
    );
};

export const NextStepButton = ({ step, buttonText, boxShadow, onClick, disabled }: IPrevNextButtonProps) => {
    return (
        <RoofcareStepButton
            step={step}
            buttonText={buttonText}
            textColor={theme.palette.common.white}
            backgroundColor={theme.palette.common.next}
            borderRadius={"47px"}
            maxWidth={"170px"}
            height={"50px"}
            onClick={onClick}
            disabled={disabled}
            boxShadow={boxShadow}
        />
    );
};
