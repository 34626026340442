import { action, computed, makeObservable } from "mobx";
import { FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { GutterDetailsStepModel, GutterDetailsStepModelValidator } from "./GutterDetailsStepModel";
import { DrainageType, DrainageTypeHelpers, RoofType, SideType } from "Models";

export class GutterDetailsStepViewModel extends ViewModelBase<GutterDetailsStepModel> {
    public apiClient = new APIClient();

    constructor() {
        super(new GutterDetailsStepModel());
        this.setValidator(new GutterDetailsStepModelValidator());
        makeObservable(this, { updateRoofType: action, getSideType: computed, side1_Height: computed, side2_Height: computed });
    }

    public clear() {
        this.model.clear();
    }

    private calculateHeight(angleDegrees: number, hypotenuse: number): number {
        let retVal: number = 0;
        const thetaDegrees: number = 180 - angleDegrees;
        const thetaRadians: number = (thetaDegrees * Math.PI) / 180;
        // Math.sin uses radians not degrees.
        // SOH = Sine theta = opposite / hypotenuse.
        // so height = hypotenuse * sine theta (probably)
        retVal = hypotenuse * Math.sin(thetaRadians);
        return retVal;
    }

    public get side1_Height(): number {
        let retVal: number = 0;

        switch (this.model.sideType) {
            case SideType.ThreeSideSym:
            case SideType.ThreeSideL:
            case SideType.ThreeSideR: {
                retVal = this.calculateHeight(this.model.side1Angle, this.model.side1Length);
                break;
            }

            /* case SideType.FourSideSym:
            case SideType.FourSideL:
            case SideType.FourSideR: {
                retVal = this.calculateHeight(this.model.side1Angle, this.model.side1Length);
                retVal += this.calculateHeight(this.model.side1Angle2, this.model.side1Length2);
                break;
            } */

            case SideType.Unknown:
            default: {
                break;
            }
        }
        return retVal;
    }

    public get side2_Height(): number {
        let retVal: number = 0;

        switch (this.model.sideType) {
            case SideType.ThreeSideSym:
            case SideType.ThreeSideL:
            case SideType.ThreeSideR: {
                retVal = this.calculateHeight(this.model.side2Angle, this.model.side2Length);
                break;
            }

            /* case SideType.FourSideSym:
            case SideType.FourSideL:
            case SideType.FourSideR: {
                retVal = this.calculateHeight(this.model.side2Angle, this.model.side2Length);
                retVal += this.calculateHeight(this.model.side2Angle2, this.model.side2Length2);
                break;
            } */

            case SideType.Unknown:
            default: {
                break;
            }
        }
        return retVal;
    }

    public get getSideType(): SideType {
        return this.model.sideType;
    }

    private updateField(fieldName: keyof FieldType<GutterDetailsStepModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public updateRoofType = (value: RoofType) => {
        this.setValue("roofType", value);
    };

    public updateSideType: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.setValue("sideType", parseInt(value.key) as SideType);
    });

    // Lengths and angles

    public updateSide1Length: ICommand = new RelayCommand((value: string) => {
        this.updateField("side1Length", parseInt(value));
    });

    public updateBottomLength: ICommand = new RelayCommand((value: string) => {
        this.updateField("bottomLength", parseInt(value));
    });

    public updateSide2Length: ICommand = new RelayCommand((value: string) => {
        this.updateField("side2Length", parseInt(value));
    });

    public updateSide1Angle: ICommand = new RelayCommand((value: string) => {
        this.updateField("side1Angle", parseInt(value));
    });

    public updateSide2Angle: ICommand = new RelayCommand((value: string) => {
        this.updateField("side2Angle", parseInt(value));
    });

    // Flat Roof Max Height

    public updateMaxHeight: ICommand = new RelayCommand((value: string) => {
        this.updateField("maxHeight", parseInt(value));
    });

    // Drainage Type

    public get getDrainageTypeOptions(): KeyValuePair<any>[] {
        return DrainageTypeHelpers.getOptions();
    }

    public updateDrainageType: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.setValue("drainageType", parseInt(value.key) as DrainageType);
    });

    public updateP1: ICommand = new RelayCommand((value: string) => {
        this.updateField("p1", parseInt(value));
    });

    public updateP2: ICommand = new RelayCommand((value: string) => {
        this.updateField("p2", parseInt(value));
    });

    public get getSelectedGutterProfile(): SideType {
        return this.model.sideType;
    }
}
