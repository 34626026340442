import styled from "@emotion/styled";
import { Box } from "Components/General";

export const CameraContainer = styled(Box)`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 15px;

    .react-html5-camera-photo > img,
    .react-html5-camera-photo > video {
        width: 100%;
    }
`;

export const PhotoCaptureOptionsWrapper = styled(Box)`
    margin-bottom: "20px";
    display: flex;
    align-items: center;
    justify-content: center;

    .take-photo-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .select-device-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .select-img {
        text-decoration: underline;
        text-underline-offset: 3px;
        cursor: pointer;

        margin-top: -10px;
        padding-left: 5px;
    }
`;

export const CancelPhotoButtonsWrapper = styled(Box)`
    display: flex;
    justify-content: center;

    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
`;

export const CancelButton = styled.button`
    border-radius: 47px;
    max-width: 170px;
    height: 50px;
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #c70101;
    color: #ffffff;
    font-family: "Mont Regular", "Montserrat", "Arial";
    font-weight: 300;
`;

export const TakenPhotoButtonsWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    width: 20%;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;

    .camera-buttons {
        background-color: transparent;
        border: none;
    }
`;

export const GalleryWrapper = styled(Box)`
    display: flex;
    flex-direction: flex;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    .gallery-image-wrapper {
        position: relative;
        width: 49%;
    }

    .gallery-image-delete {
        position: absolute;
        top: 5%;
        right: 5%;
        background-color: transparent;
        border: none;
    }
`;
