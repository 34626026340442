import { DetailsList, DetailsListLayoutMode, IColumn, IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IRenderFunction, SelectionMode, TooltipHost } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

import { ICommand } from "../../../Application/Commands";
import { IDetailsListStyles } from "@fluentui/react/lib/components/DetailsList/DetailsList.types";
import { Box } from "../../General/Box";

/**
 * Button interface.
 */
export interface IDetailsListProps {
    /**
     * An optional id for use with the button.
     */
    id?: string;
    /**
     * Pass in the data items to display in the list.
     */
    data: any[];
    /**
     * Pass in the columns to display.
     */
    columns: IColumn[];
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * An optional boolean to select a single row.
     */
    selectSingle?: boolean;
    /**
     * An optional boolean to select multiple rows.
     */
    selectMultiple?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the control. Will be passed back by the command.
     */
    value: () => string[];
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * Styling of the control.
     */
    styles?: Partial<IDetailsListStyles>;
}

export const DataTableBase: React.FC<IDetailsListProps> = observer((props) => {
    const getClassNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = (vent: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        props.command.execute(newValue);
    };

    const getStyles = (): Partial<IDetailsListStyles> | undefined => {
        return !isNullOrUndefined(props.styles) ? props.styles : undefined;
    };

    const getSelection = (): SelectionMode => {
        if (props.selectSingle) {
            return SelectionMode.single;
        } else if (props.selectMultiple) {
            return SelectionMode.multiple;
        } else {
            return SelectionMode.none;
        }
    };

    // #endregion Code Behind
    const renderFixedDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => <TooltipHost {...tooltipHostProps} />;
        return (
            <Box style={{ fontSize: "100px" }}>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Box>
        );
    };

    return (
        <DetailsList
            className={getClassNames()}
            items={props.data}
            columns={props.columns}
            setKey="set"
            selectionMode={getSelection()}
            layoutMode={DetailsListLayoutMode.justified}
            //selection={this._selection}
            selectionPreservedOnEmptyClick={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="select row"
            //onItemInvoked={this._onItemInvoked}
            styles={getStyles()}
        />
    );
});
