import styled from "@emotion/styled";
import { AssistantCommon } from "../Assistant-style";
import background from "Assets/InstallHeight.png";
import { theme } from "Styles";
import { Box } from "Application";

export const InstallHeightWrapper = styled(AssistantCommon)`
    margin-bottom: 30px;
    width: auto;
`;

export const InstallHeightBox = styled(Box)`
    margin-top: 10px;
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    width: 100%;
    padding: 0;
    /* height: 290px; */
    max-height: 573px;
    /* max-height: 290px; */

    background-color: #1d7698;

    margin-bottom: 250px;

    .installheightimg {
        min-height: 290px;
        max-height: 573px;
        background-image: url(${background});
        background-repeat: no-repeat;
        background-size: cover;
        width: 50%;
        /* width: 190px;
        max-width: 190px;
        min-width: 190px; */
    }

    .installheightimgtablet {
        height: 573px;
        background-image: url(${background});
        background-repeat: no-repeat;
        background-size: cover;
        width: 50%;
    }

    .installHeightData {
        padding: 10px;
        display: flex;
        flex: 1 1 1;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;

        .text {
            max-height: 150px;
        }

        .data {
            border-top: 1px dashed ${theme.palette.common.white};
            padding-bottom: 30px;
        }
    }
`;
