import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { InstallationModel } from "../InstallationModel";
import { Client, Contractor, Device, DeviceStatusData, InstallationCommissioned, InstallationCommissionImage, Property } from "Models";

export class GetQRCodeDetailsRequest {
    public qRCodeData: string = "";

    constructor() {}
}

export class GetQRCodeDetailsResponse {
    public device?: Device;
    public contractor?: Contractor;
    public client?: Client;
    public properties: Property[] = [];
    public deviceStatus?: DeviceStatusData;
    public installationCommissioned?: InstallationCommissioned;
    public installationCommissionImages?: InstallationCommissionImage[];

    constructor() {}
}

export class ProcessQREndpoint extends Endpoint<GetQRCodeDetailsRequest, GetQRCodeDetailsResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Unit.GetQRCodeDetails);
    }

    public async HandleRequestAsync(model: InstallationModel): Promise<any> {
        let request = new GetQRCodeDetailsRequest();
        request.qRCodeData = model.qrCode;
        return request;
    }

    public async HandleResponseAsync(response: GetQRCodeDetailsResponse): Promise<GetQRCodeDetailsResponse> {
        Logger.logInformation("Response", response);

        //Return your model

        return response;
    }
}
