import { css, Global } from "@emotion/react";
import React from "react";
import { theme } from "./AppTheme";
import background from "../Assets/background.png";

export const GlobalStyles = () => {
    const scrollbar_backgroundcolor = theme.palette.secondary.light;
    const scrollbar_backgroundbordercolor = "#DCDCDC";

    return (
        <Global
            styles={css`
                html,
                body {
                    margin: 0;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    text-rendering: optimizeLegibility;
                    height: 100%;

                    font-family: ${theme.defaultFontStyle.fontFamily};
                    font-size: ${theme.defaultFontStyle.fontSize};
                    line-height: ${theme.defaultFontStyle.lineHeight};

                    /* Changed from hidden - JW */
                    overflow: hidden;

                    /* background-image: url(${background}); */
                    background-color: #f29d29;
                    background-repeat: no-repeat;
                    background-size: cover;

                    color: ${theme.palette.common.white};

                    .ms-Dialog-main {
                        display: block;
                        background-color: rgb(229, 140, 19);
                        height: auto;
                        min-height: 136px !important;
                        overflow: hidden;
                        border-radius: 5px;
                    }

                    .loadingscreen {
                        .innerbox {
                            background-color: rgb(229, 140, 19);
                            color: white;
                            display: flex;
                            flex: 1 0 0;
                            width: auto;
                            vertical-align: middle;
                            padding: 15px 30px;
                            height: fit-content;
                        }
                    }
                }

                //Take these out
                /* h1 {
                    font-weight: 300;
                    letter-spacing: -0.7pt;
                    margin-top: 5px;
                    font-family: Montserrat;
                }

                h2 {
                    font-weight: 300;
                    letter-spacing: -0.46pt;
                    margin-bottom: 20px;
                    line-height: 0.8;
                    font-size: 20px;
                    font-family: Montserrat;
                }

                h3 {
                    font-weight: 300;
                    letter-spacing: -0.46pt;
                    line-height: 0.8;
                    font-size: 16px;
                    font-family: Montserrat;
                }

                h6 {
                } */

                .pageWrapperContainer {
                }
                .mobileMaxwidth {
                    width: 100%;

                    //Mobile
                    /* max-width: 390px; */
                    max-width: 768px;
                    //Tablet

                    /* @media only screen and (min-width: 768px) {
                        /* For desktop: */
                    // max-width: 768px;
                    //} */

                    //Tablet max width
                    /* max-width: 768px; */
                    //Old mobile-only width
                    /* max-width: 390px; */
                }
                .overflowContainer {
                    overflow: auto;
                    /* height changed from 95vh  - JW */
                    /* height: 95vh; */
                    background-color: transparent;
                    padding-bottom: 50px;
                    bottom: 0;
                }
                code {
                    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
                }

                /////////////////////////////////////////
                //Custom scroll bar
                * {
                    scrollbar-width: thin;
                    scrollbar-color: ${scrollbar_backgroundcolor} ${scrollbar_backgroundbordercolor};
                }

                // Chrome, Edge, and Safari - more overrides available, including radius, width and thumb borders.
                *::-webkit-scrollbar {
                    height: 9px;
                    width: 9px;
                }

                *::-webkit-scrollbar-track {
                    background: ${scrollbar_backgroundbordercolor};
                }

                *::-webkit-scrollbar-thumb {
                    background-color: ${scrollbar_backgroundcolor};
                    border-radius: 9px;
                    border: 1px solid ${scrollbar_backgroundbordercolor};
                }
                //End of custom scroll bar

                .instructionModal {
                    /*                     overflow: none;
                    border-radius: 5px;
                    width: 100%;
                    max-width: 390px; */

                    .react-pdf__Document {
                        position: relative;
                        margin: 0 auto;
                        min-width: 100% !important;
                        max-width: 100% !important;
                        width: 80% !important;
                        height: 100% !important;
                    }

                    .react-pdf__Page {
                        position: relative;
                        min-width: 100% !important;
                        max-width: 100% !important;
                    }
                    .react-pdf__Page .react-pdf__Page__canvas {
                        position: relative;
                        margin: 0 auto;
                        min-width: 100% !important;
                        max-width: 100% !important;
                        width: 100% !important;
                        height: 100% !important;
                    }

                    .react-pdf__Page__annotations {
                        height: 0px !important;
                    }
                }

                .ms-Modal-scrollableContent {
                    overflow: hidden !important;
                }

                .ms-Dialog-main {
                    overflow: hidden !important;
                }

                .modalcontainer {
                    overflow: hidden !important;
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: stretch;
                    /* max-height: calc(100vh - 32px); */
                }

                .modalinstruction {
                    display: flex;
                    flex: 1 1 1;
                    width: 100%;
                    overflow: auto;
                    height: 80vh;
                    background-color: transparent;
                }

                .instructionmodalcontainer {
                    display: flex;
                    flex: 1 1 1;
                }

                .mmSuffix {
                    font-family: ${theme.defaultFontStyle.fontFamily};
                    font-size: ${theme.defaultFontStyle.fontSize};
                    line-height: ${theme.defaultFontStyle.lineHeight};
                    font-weight: 700;
                    padding-right: 10px;
                    color: ${theme.palette.common.black};
                    max-width: 45px;
                }

                .heightSuffix {
                    font-family: ${theme.defaultFontStyle.fontFamily};
                    font-size: ${theme.defaultFontStyle.fontSize};
                    line-height: ${theme.defaultFontStyle.lineHeight};
                    font-weight: 700;
                    padding-right: 5px;
                    color: ${theme.palette.common.suffixName};
                    max-width: 65px;
                }

                .dualsuffix {
                    display: flex;
                    flex: 1 0 0;
                    flex-direction: row;
                }

                .leaflet-control-attribution {
                    display: none;
                }
                /////////////{////////////{////////////////
            `}
        />
    );
};
