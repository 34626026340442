import { KeyValuePair } from "@shoothill/core";

export enum DrainageType {
    Gravity = 1,
    SingleSiphonic = 2,
    DualSiphonic = 3,
}

export class DrainageTypeHelpers {
    public static getText = (drainType: DrainageType) => {
        switch (drainType) {
            case DrainageType.DualSiphonic: {
                return "Dual Siphonic";
            }
            case DrainageType.SingleSiphonic: {
                return "Single Siphonic";
            }
            case DrainageType.Gravity:
            default: {
                return "Gravity";
            }
        }
    };

    //public static getOptions = (ignoreUnknown: boolean = false) => {
    public static getOptions = (): KeyValuePair[] => {
        const options: KeyValuePair[] = [];

        for (const key in DrainageType) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as DrainageType;

                //if (ignoreUnknown === false || item !== SensorType.Unknown) {
                options.push({
                    key: keyAsInt.toString(),
                    text: DrainageTypeHelpers.getText(item),
                });
                //}
            }
        }

        return options;
    };
}
