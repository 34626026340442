import React from "react";
import { HomeView } from "./Views/Home/HomeView";
import { LayoutNoHeaderOrFooter } from "Views/Layouts/LayoutNoHeaderOrFooter";
import { CommonInjects } from "./Application/CommonInjects";
import { Route, Route as ReactRoute, Routes } from "react-router-dom";
import { DebugRouter } from "./Components/DebugRouter";

import { DefaultLayoutNoFooter } from "./Views/Layouts";
import { InstructionsView } from "Views/Instructions/InstructionsView";
import { AssistantView } from "Views/Assistant/AssistantView";
import { AppUrls } from "AppUrls";
import { BackTestView } from "Views/Samples/BackTest";

export const AppRoutes: React.FC = () => {
    return (
        <>
            <DebugRouter>
                <Routes>
                    {/*                     <Route element={<DefaultLayout />}>
                        <Route path={"/stylesheet"} element={<StyleSheetView />} />
                    </Route>

                    <Route element={<LayoutNoHeaderOrFooter />}></Route> */}

                    {/* DONT USE Moved into CustomRouter                    <Route element={<DefaultLayoutNoFooter />}>
                        <Route path={AppUrls.Client.Home} index element={<HomeView />} />
                        <Route path={AppUrls.Client.Instructions} index element={<InstructionsView />} />
                        <Route path={AppUrls.Client.Assistant.Start} index element={<AssistantView />} />
                    </Route> */}

                    {/*404 Route*/}
                    <ReactRoute
                        path={"*"}
                        element={
                            <main style={{ padding: "1rem" }}>
                                <p>There's nothing here!</p>
                            </main>
                        }
                    />
                </Routes>
            </DebugRouter>

            <CommonInjects />
        </>
    );
};
