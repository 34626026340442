import styled from "@emotion/styled";
import { theme } from "../../Styles";

interface InputBoxProps {
    shape?: string;
    displayClearButton?: boolean;
}
export const InputWrapper = styled.div<InputBoxProps>`
    display: flex;
    flex-direction: column;
    align-self: end;

    border-radius: 5pt;
    .inputbox {
        display: flex;
        flex: 1 1 0;
        flex-direction: row;
        align-items: center;
        background-color: white;
        box-sizing: content-box;
        border-radius: 5pt;
        border: 1px solid ${theme.palette.common.borders};
        transition: 0.5s ease all;

        //check for focus on the child input
        &:hover {
            border: 1px solid ${theme.palette.field.lighter};
        }
        &:focus-within {
            border: 1px solid ${theme.palette.common.focus};
        }
        //react-datetime
        .rdt {
            width: 100%;
        }
    }
    input,
    textarea {
        /*  Theme properties*/
        color: ${theme.palette.field.text};
        font-family: ${theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily};
        font-size: ${theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize};
        font-weight: ${theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight};
        letter-spacing: ${theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing};
        line-height: ${theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight};
        text-transform: ${theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform};
        padding: ${(p) => (p.displayClearButton !== undefined && p.displayClearButton === true ? "10px" : "10px 0 10px 10px")};
        /*End theme properties*/
        /* Resets */
        width: ${(p) => (p.displayClearButton !== undefined && p.displayClearButton === true ? "calc(100% - 24px)" : "100%")};

        outline: 0;
        border: 0;
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: 0.5s ease all;
        /* End Resets */

        ::placeholder {
            color: #8d8d8d;
        }
    }
`;

export const FieldLabel = styled.label`
    font-family: ${theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily};
    font-size: ${theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize};
    font-weight: ${theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight};
    letter-spacing: ${theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing};
    line-height: ${theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight};
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
    min-height: 20px;
`;

export const ErrorBox = styled.div`
    padding: 8px 0px;
`;
