import { makeObservable } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { SensorLocationStepModel, SensorLocationStepModelValidator } from "./SensorLocationStepModel";
import { AssistantViewModel } from "../AssistantViewModel";

export class SensorLocationStepViewModel extends ViewModelBase<SensorLocationStepModel> {
    private parent: AssistantViewModel;
    public apiClient = new APIClient();

    constructor(parent: AssistantViewModel) {
        super(new SensorLocationStepModel());
        this.setValidator(new SensorLocationStepModelValidator());
        this.parent = parent;
        makeObservable(this, {});
    }

    public clear() {
        this.model.clear();
    }

    private updateField(fieldName: keyof FieldType<SensorLocationStepModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public updateLocationDescription: ICommand = new RelayCommand((value: string) => {
        this.setValue("locationDescription", value);
        // console.log(this.getValue("locationDescription"));
    });

    public updateDraggedMarkerValues = (lat: number, lng: number) => {
        this.parent.setValue("draggedLat", lat);
        this.parent.setValue("draggedLng", lng);
        console.log(this.parent.getValue("draggedLat"));
        console.log(this.parent.getValue("draggedLng"));
    };
}
