import React from "react";
import { observer } from "mobx-react-lite";
import { AssistantViewModel } from "../AssistantViewModel";
import { OnlyOneButton } from "../Assistant-style";
import { AssistantSteps } from "../AssistantSteps";
import { NextButton } from "Components/Buttons";
import { AppUrls } from "AppUrls";
import { EndStepWrapper, EndStep, EndStepTop, EndStepBottom } from "./EndStepView-style";
import { Property } from "Models";
import { CoreStoreInstance } from "@shoothill/core";

export interface IStepEndStep {
    parentViewModel: AssistantViewModel;
}

export const EndStepView: React.FC<IStepEndStep> = observer(({ parentViewModel }) => {
    const onPrev = (step: AssistantSteps) => {
        // We can go back regardless of state of the local viewModel
        parentViewModel.setStep(step);
    };

    const onNext = (step: AssistantSteps) => {
        // TODO Need to only do this if the local viewModel is valid
        parentViewModel.setStep(step);
    };

    const property: Property | undefined = parentViewModel.endUserViewModel.getSelectedPropertyFromSearch;

    // 10274 Sort out the error on the last page
    CoreStoreInstance.HideInfoBar();

    return (
        <EndStepWrapper>
            <EndStep>
                <EndStepTop>
                    <h2>Sim Id: {parentViewModel.model.simId}</h2>
                    <h3>Installation submitted</h3>
                    <p>Please wait for 3-5 minutes your confirmation text message</p>
                </EndStepTop>
                <EndStepBottom>
                    {property !== undefined && (
                        <>
                            {property.buildingName !== undefined && property.buildingName !== null && property.buildingName.length > 0 && <p>{property.buildingName}</p>}
                            {property.addressLine1.length > 0 && <p>{property.addressLine1}</p>}
                            {property.addressLine2 !== undefined && property.buildingName !== null && property.addressLine2.length > 0 && <p>{property.addressLine2}</p>}
                            {property.city.length > 0 && <p>{property.city}</p>}
                            {property.county.length > 0 && <p>{property.county}</p>}
                            {property.postcode.length > 0 && <p>{property.postcode}</p>}
                        </>
                    )}
                </EndStepBottom>
            </EndStep>
            <OnlyOneButton>
                <NextButton url={AppUrls.Client.Home} buttonText={"Start new installation"} />
            </OnlyOneButton>
        </EndStepWrapper>
    );
});
