import { ModelBase } from "@shoothill/core";
import { makeObservable, action, observable } from "mobx";
import { Validator } from "Application/Validation";

export class InstallHeightModel extends ModelBase<InstallHeightModel> {
    public installHeight: number = 0;
    public firstValidate: boolean = true;

    constructor() {
        super();
        makeObservable(this, {
            installHeight: observable,
            clear: action,
        });
    }

    public clear() {
        this.installHeight = 0;
    }
}

export class InstallHeightModelValidator extends Validator<InstallHeightModel> {
    constructor() {
        super();
        this.ruleFor("installHeight")
            .greaterThanOrEqualTo(1)
            .withMessage("Height must be greater than 0")
            .lessThanOrEqualTo(1000)
            .when((model) => model.firstValidate === false);
    }
}
