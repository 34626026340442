import styled from "@emotion/styled";
import { Box } from "Components/General/Box";

export const AssistantCommon = styled(Box)`
    /*     width: 100%;
    max-width: 100%;
    display: flex;
    flex: 1 0 0;
    flex-direction: column; */
    width: 100%;
    max-width: 512px;

    /* padding: 0 20px; */
`;

export const AssistantStepView = styled.div`
    /* width: 100%; */
    padding: 15px 5px 15px 0;
`;

export const PreviousNext = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    column-gap: 10px;
`;

export const OnlyOneButton = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;
