import { makeObservable } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ContractorStepModel, ContractorStepModelValidator } from "./ContractorStepModel";
import { Contractor } from "Models";

export class ContractorStepViewModel extends ViewModelBase<ContractorStepModel> {
    public apiClient = new APIClient();

    public firstNameValid: boolean = true;

    constructor() {
        super(new ContractorStepModel());
        this.setValidator(new ContractorStepModelValidator());
        makeObservable(this, {});
    }

    public clear() {
        this.model.clear();
    }

    public load(contractor: Contractor) {
        this.model.load(contractor);
    }

    private updateField(fieldName: keyof FieldType<ContractorStepModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public updateContractorName: ICommand = new RelayCommand((value: string) => {
        if (value !== this.model.contractorName) {
            this.updateField("contractorName", value);
        }
    });

    public updateFirstName: ICommand = new RelayCommand((value: string) => {
        this.updateField("contactFirstName", value);
    });

    public updateSurname: ICommand = new RelayCommand((value: string) => {
        this.updateField("contactSurname", value);
    });

    public updateContactMobile: ICommand = new RelayCommand((value: string) => {
        this.updateField("contactMobile", value);
    });
}
