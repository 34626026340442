import styled from "@emotion/styled";
import { Box, theme } from "Application";

export const Main = styled.div`
    margin-top: auto;
    .MuiTypography-h6 {
        text-transform: none;
    }
    a {
        text-decoration: none;
    }

    h6 {
        font-weight: bold;
        font-size: 0.95rem !important;
    }

    @media (max-width: 767px) {
        svg.logo {
            max-width: 75%;
        }

        .MuiTypography-body1 {
            font-size: 0.75rem;
        }

        h6 {
            font-weight: bold;
            font-size: 0.75rem;
        }
    }
`;

export const SocialsGrid = styled(Box)`
    @media (max-width: 767px) {
        justify-items: center;
    }
`;

export const ExternalLink = styled.a`
    color: ${theme.palette.secondary.main};
    font-size: 1em;
`;
