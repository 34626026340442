import { ViewModelBase } from "@shoothill/core";
import { action, makeObservable, observable } from "mobx";

export class HeaderViewModel extends ViewModelBase<any> {
    public activeMenuItem: string = "Home";

    constructor() {
        super({});
        makeObservable(this, {
            activeMenuItem: observable,
            setActiveMenuItem: action,
        });
    }

    public setActiveFromLocation = (location: string, menuPath: string, menuText: string): void => {
        const locationSegment = location.split("/")[1].toLowerCase();
        const pathSegment = menuPath.split("/")[1].toLowerCase();
        if (location.toLowerCase() === menuPath.toLowerCase() || locationSegment === pathSegment) {
            this.setActiveMenuItem(menuText);
        }
    };

    public setActiveMenuItem(menuItem: string): void {
        this.activeMenuItem = menuItem;
    }

    // #endregion Computeds
}
