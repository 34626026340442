import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Label, MaskedOptions } from "Application";
import { AssistantViewModel } from "../AssistantViewModel";
import { AssistantStepView, PreviousNext } from "../Assistant-style";
import { AssistantSteps } from "../AssistantSteps";
import { NextStepButton } from "Components/Buttons";
import stepIcon from "Assets/step01.svg";
import stepIconTablet from "Assets/step01tablet.svg";
import { ContractorStepViewModel } from "./ContractorStepViewModel";
import { RoofcareInput } from "Styles/BaseStyles";
import { ContractorWrapper } from "./ContractorStepView-style";
import { CoreStoreInstance } from "@shoothill/core";

export interface IStepContractor {
    parentViewModel: AssistantViewModel;
}

export const ContractorStepView: React.FC<IStepContractor> = observer(({ parentViewModel }) => {
    const [viewModel] = useState<ContractorStepViewModel>(parentViewModel.contractorViewModel);

    CoreStoreInstance.CoreOptions.mobileBreakPoint = 600;

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(window.scrollY);
        return () => {};
    }, []);

    CoreStoreInstance.isInfoBarOpen;

    useEffect(() => {
        if (viewModel.isModelValid(true, true) === true) {
            CoreStoreInstance.HideInfoBar();
        }
    }, [viewModel.isModelValid(true, true)]);

    function onNext(step: AssistantSteps): void {
        viewModel.setValue("firstValidate", false);

        if (viewModel.isModelValid(true, true) === true) {
            parentViewModel.setStep(step);
        }
    }
    const allowNextStep: boolean = viewModel !== undefined && viewModel !== null ? viewModel.isModelValid(true, true) : false;

    return (
        <ContractorWrapper padding={viewModel.isMobile ? "0 20px 0 20px" : "0px"}>
            <AssistantStepView className={viewModel.isMobile ? "mobile" : "tablet"}>
                <img className="icon" width={"100%"} src={viewModel.isMobile ? stepIcon : stepIconTablet} alt="contractor step" />
            </AssistantStepView>

            <div className={"test1"}>
                <p style={{ marginBottom: "10px" }}>SIM ID: {parentViewModel.model.simId}</p>
            </div>

            <Label fontStyle="h1" color={"headerText"}>
                Step 1
            </Label>

            <Label fontStyle="h2" color={"headerText"} mb={"16px"}>
                Contractor info
            </Label>

            <RoofcareInput
                displayClearButton={false}
                noHeader={true}
                displayName=""
                command={viewModel.updateContractorName}
                placeholder="Contractor Name"
                value={() => viewModel.getValue("contractorName")}
                validationMessage={() => viewModel.getError("contractorName")}
            />
            <RoofcareInput
                displayName="Installer first name"
                command={viewModel.updateFirstName}
                placeholder="First Name"
                value={() => viewModel.getValue("contactFirstName")}
                validationMessage={() => viewModel.getError("contactFirstName")}
            />
            <RoofcareInput
                displayName="Installer last name"
                value={() => viewModel.getValue("contactSurname")}
                command={viewModel.updateSurname}
                placeholder={"Last Name"}
                validationMessage={() => viewModel.getError("contactSurname")}
            />
            <div style={{ marginBottom: "150px", marginTop: "35px" }}>
                <Label fontStyle="h2" color={"headerText"}>
                    Activation
                </Label>
                <RoofcareInput
                    displayName="Please add a mobile number (for activation txt)"
                    command={viewModel.updateContactMobile}
                    value={() => viewModel.getValue("contactMobile")}
                    validationMessage={() => viewModel.getError("contactMobile")}
                    maskedOptions={MaskedOptions.MobilePhoneNumber()}
                />
            </div>
            <PreviousNext style={{ justifyContent: "center" }}>
                <NextStepButton disabled={!allowNextStep} step={AssistantSteps.EndUser} buttonText={"Next step"} onClick={onNext} boxShadow={"0pt 3pt 6pt #00000059"} />
            </PreviousNext>
        </ContractorWrapper>
    );
});
