import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState } from "Application";
import { HomeViewModel, HomeViewModelValidator } from "./HomeViewModel";

export class HomeViewViewModel extends ViewModelBase<HomeViewModel> {
    public apiClient = new APIClient();

    constructor() {
        super(new HomeViewModel());
        this.setValidator(new HomeViewModelValidator());
        makeObservable(this, {});
    }
}
