import { AppRoutes } from "./AppRoutes";
import React from "react";
import { GlobalHistory } from "index";
import { CustomThemeProvider, theme } from "./Application";
import { CustomBrowserRouter } from "Components/CustomRouter";
import { GlobalStyles } from "./Styles/GlobalStyles";
import { pdfjs } from "react-pdf";

export const App: React.FC = () => {
    //pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    //pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;
    return (
        <>
            <CustomThemeProvider>
                <GlobalStyles />
                <CustomBrowserRouter history={GlobalHistory} basename="">
                    <AppRoutes />
                </CustomBrowserRouter>
            </CustomThemeProvider>
        </>
    );
};
