import React from "react";
import { observer } from "mobx-react-lite";
import { Label } from "Application";
import { Modal } from "@fluentui/react";
import { ModalBody, ModalHeader } from "./ActivationInstructionStep/ModalStyle";

export interface IErrorModal {
    open: boolean;
    title: string;
    message1: string;
    message2?: string;

    cancelText?: string;
    acceptText?: string;

    onClose: () => void;
    onAccept?: () => void;
}

export const ErrorModal: React.FC<IErrorModal> = observer(({ open, title, message1, message2, cancelText, acceptText, onClose, onAccept }) => {
    const showMessage2: boolean = message2 !== null && message2 !== undefined && message2.length > 0 ? true : false;

    const cancelBtnText: string = cancelText == null ? "Close" : cancelText;
    const acceptBtnText: string = acceptText == null ? "Accept" : acceptText;

    const showAcceptbtn: boolean = acceptText !== undefined && onAccept !== undefined;

    return (
        <>
            <Modal isOpen={open} onDismiss={() => onClose}>
                <ModalHeader>
                    <div className="title">
                        <p>{title}</p>
                    </div>
                    <div className="close" onClick={() => onClose()}>
                        <p>X</p>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="errorLine">
                            <Label fontStyle="h3" color="headerText">
                                {message1}
                            </Label>
                        </div>
                        {showMessage2 && (
                            <div className="errorLine">
                                <Label fontStyle="h3" color="headerText">
                                    {message2}
                                </Label>
                            </div>
                        )}
                    </div>

                    <div>
                        {showAcceptbtn === true && (
                            <button className="errorCloseButton" onClick={() => onAccept!()}>
                                {acceptBtnText}
                            </button>
                        )}
                        <button className="errorCloseButton notfirst" onClick={() => onClose()}>
                            {cancelBtnText}
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
});
