import {
    AccentColours,
    CommonColours,
    FontVariant,
    themeColourOptions,
    themeFontStyleOptions,
    themePaletteOptions,
    themeShapeOptions,
    themeSizeOptions,
} from "../../Styles/IShoothillTheme";
import { theme } from "../../Styles";
import { KeyValuePair } from "@shoothill/core";
import { IChoiceGroupOptionStyles, IComboBoxOption, IComboBoxOptionStyles, mergeStyleSets } from "@fluentui/react";
import { IChoiceGroupOption } from "@fluentui/react/lib/components/ChoiceGroup/ChoiceGroup.types";

export const pxToRem = (value: number) => {
    return `${value / 16}rem`;
};

//Util function to convert desktop version of AdobeXD AV value to REM
export const adobeXDToRem = (fontSize: number, letterSpacing: number): string => {
    return pxToRem((letterSpacing / 1000) * fontSize) + "rem";
};
export const remToPx = (rem?: string): string => {
    if (rem) {
        const newRem = parseFloat(rem.replace("rem", ""));
        return newRem * parseFloat(getComputedStyle(document.documentElement).fontSize) + "px";
    }
    return "16px";
};

export const getThemePaletteOption = (option?: themePaletteOptions): AccentColours => {
    return theme.palette[option as string] || theme.palette.default;
};

export const getThemeColorOption = (option: themeColourOptions): string => {
    return theme.palette.common[option as string] || theme.palette.common.black;
};

export const getThemeSizeOption = (option: themeSizeOptions): string => {
    return theme.sizes[option as string] || theme.sizes.large;
};

export const getThemeShapeOption = (option: themeShapeOptions): string => {
    switch (option) {
        case "round":
            return "100em";

        case "square":
        default:
            return pxToRem(2);
    }
};

export const getThemeFontStyleOption = (option: themeFontStyleOptions): FontVariant => {
    return theme.fontStyles[option as string] || theme.fontStyles.p;
};

export const convertTeamsToChoiceGroupOptions = (items: KeyValuePair[], styles?: IChoiceGroupOptionStyles): IChoiceGroupOption[] => {
    return items.map((item) => {
        return <IChoiceGroupOption>{
            key: item.key,
            text: item.text,
            styles: styles,
        };
    });
};

export const convertTeamsToComboBoxOptions = (items: KeyValuePair[], styles?: IComboBoxOptionStyles): IComboBoxOption[] => {
    return items.map((item, index) => {
        return <IComboBoxOption>{
            key: item.key,
            text: item.text,
            styles: mergeStyleSets(
                {
                    root: {
                        backgroundColor: index % 2 === 0 ? "red" : "green",
                    },
                },
                styles,
            ),
        };
    });
};
