import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { EditSelect, Label, MaskedOptions } from "Application";
import { AssistantViewModel } from "../AssistantViewModel";
import { AssistantStepView, PreviousNext } from "../Assistant-style";
import { AssistantSteps } from "../AssistantSteps";
import { NextStepButton, PrevStepButton } from "Components/Buttons";
import stepIcon from "Assets/step04.svg";
import stepIconTablet from "Assets/step04tablet.svg";

import pitchedIcon from "Assets/ButtonPitched.svg";
import flatIcon from "Assets/ButtonFlat.svg";

import {
    DrainageTypeBox,
    FlatProfile,
    GutterDetailsWrapper,
    GutterType,
    GutterTypeRow,
    ImageOption,
    PitchedProfile,
    ProfileEntry,
    ProfileImage,
    ProfileSplitter,
    ProfileWrapper,
    ProfileEntryRow,
} from "./GutterDetailsStepView-style";
import { GutterDetailsStepViewModel } from "./GutterDetailsStepViewModel";
import { RoofcareInput } from "Styles/BaseStyles";
import { DoubleSuffix, SingleSuffix } from "../Suffix";

import { SideTypeHelpers, SideType } from "../../../Models/SideType";
import { CoreStoreInstance } from "@shoothill/core";
import ThreeSideL from "Assets/3 side L.svg";
import ThreeSideR from "Assets/3 side R.svg";
import ThreeSideSym from "Assets/3 side SYM.svg";
/* import ThreeSideLW from "Assets/3 side L -w.svg";
import ThreeSideRW from "Assets/3 side R -w.svg";
import ThreeSideSymW from "Assets/3 side SYM -w.svg";
import FourSideL from "Assets/4 side L.svg";
import FourSideR from "Assets/4 side R.svg";
import FourSideSym from "Assets/4 side SYM.svg";
import FourSideLW from "Assets/4 side L -w.svg";
import FourSideRW from "Assets/4 side R -w.svg";
import FourSideSymW from "Assets/4 side SYM -w.svg"; */

// Detailed Large Images
import ThreeSideSymLarge from "Assets/3-side-sym-large.svg";
import ThreeSideLeftLarge from "Assets/3-side-left-large.svg";
import ThreeSideRightLarge from "Assets/3-side-right-large.svg";
import { DrainageType, RoofType } from "Models";

export interface IStepGutterDetails {
    parentViewModel: AssistantViewModel;
}

export const GutterDetailsStepView: React.FC<IStepGutterDetails> = observer(({ parentViewModel }) => {
    const [viewModel] = useState<GutterDetailsStepViewModel>(parentViewModel.gutterDetailsViewModel);

    const [roofType, setRoofType] = useState<RoofType>(RoofType.Pitched);
    const [drainageType, setDrainageType] = useState<DrainageType>(DrainageType.Gravity);

    // Scrolling to top - not working!

    const scrollRef = useRef<any>(null);
    const executeScroll = () => {
        scrollRef.current.scroll({
            top: 0,
            behavior: "smooth",
        });
        console.log("Clicked");
    };

    useEffect(() => {
        return () => {};
    }, []);

    useEffect(() => {
        setRoofType(viewModel.model.roofType);
    }, [viewModel.model.getRoofType]);

    useEffect(() => {
        setDrainageType(viewModel.model.drainageType);
    }, [viewModel.model.getDrainageType]);

    CoreStoreInstance.isInfoBarOpen;

    useEffect(() => {
        if (viewModel.isModelValid(true, true) === true) {
            CoreStoreInstance.HideInfoBar();
        }
    }, [viewModel.isModelValid(true, true)]);

    const onPrev = (step: AssistantSteps) => {
        // We can go back regardless of state of the local viewModel
        parentViewModel.setStep(step);
    };

    const onNext = (step: AssistantSteps) => {
        // TODO Need to only do this if the local viewModel is valid
        viewModel.setValue("firstValidate", false);

        if (viewModel.isModelValid(true, true) === true) {
            parentViewModel.setStep(step);
        }
    };

    const allowNextStep: boolean = true; // TODO Need to only do this if the local viewModel is valid

    const toggleRoofType = (): void => {
        if (viewModel.model.roofType === RoofType.Pitched) {
            viewModel.updateRoofType(RoofType.Flat);
        } else {
            viewModel.updateRoofType(RoofType.Pitched);
        }
    };

    const togglePitchedRoofType = (): void => {
        viewModel.updateRoofType(RoofType.Pitched);
    };

    const toggleFlatRoofType = (): void => {
        viewModel.updateRoofType(RoofType.Flat);
    };

    /*     const onGutterProfileChange = (e: any): void => {
        viewModel.updateSideType(Number(e.target.value));
    }; */
    const getDropdown = (item: string) => {
        return (
            <div>
                <p className="profile">{item}</p>
            </div>
        );
    };

    const getImage = (option: string) => {
        const item = parseInt(option) as SideType;
        switch (item) {
            /*             case SideType.FourSideL: {
                return "4 Side Left";
            }
            case SideType.FourSidesR: {
                return "4 Side Right";
            }
            case SideType.FourSideSym: {
                return "4 Side Sym";
            } */
            case SideType.ThreeSideL: {
                return (
                    <ImageOption>
                        <img className="icon" src={ThreeSideL} alt={SideTypeHelpers.getText(item)} />
                        <p className="profile">{SideTypeHelpers.getText(item)}</p>
                    </ImageOption>
                );
            }
            case SideType.ThreeSideR: {
                return (
                    <ImageOption>
                        <img className="icon" src={ThreeSideR} alt={SideTypeHelpers.getText(item)} />
                        <p className="profile">{SideTypeHelpers.getText(item)}</p>
                    </ImageOption>
                );
            }
            case SideType.ThreeSideSym: {
                return (
                    <ImageOption>
                        <img className="icon" src={ThreeSideSym} alt={SideTypeHelpers.getText(item)} />
                        <p className="profile"> {SideTypeHelpers.getText(item)}</p>
                    </ImageOption>
                );
            }

            default: {
                return <div></div>;
            }
        }
    };

    return (
        <>
            <GutterDetailsWrapper padding={viewModel.isMobile ? "0 20px 0 20px" : "0px"}>
                <AssistantStepView>
                    <img className="icon" width={"100%"} src={viewModel.isMobile ? stepIcon : stepIconTablet} alt="gutter details step" />
                </AssistantStepView>
                <div>
                    <p style={{ marginBottom: "10px" }}>SIM ID: {parentViewModel.model.simId}</p>
                </div>
                <Label fontStyle="h1" color="headerText">
                    Step 4
                </Label>
                <Label fontStyle="h2" color="headerText" mb={"16px"}>
                    Gutter details
                </Label>

                <GutterType>
                    <GutterTypeRow>
                        <div className={"roofType pitched " + (viewModel.model.roofType === RoofType.Pitched ? "selected" : "")} onClick={togglePitchedRoofType}>
                            Pitched
                        </div>
                        <div className="switchbutton" onClick={() => toggleRoofType()}>
                            {roofType === RoofType.Pitched && <img className="icon" src={pitchedIcon} alt="pitched" />}
                            {roofType === RoofType.Flat && <img className="icon" src={flatIcon} alt="flat" />}
                        </div>
                        <div className={"roofType flat " + (viewModel.model.roofType === RoofType.Flat ? "selected" : "")} onClick={toggleFlatRoofType}>
                            <div>Flat</div>
                        </div>
                    </GutterTypeRow>
                </GutterType>
                {roofType === RoofType.Pitched && (
                    <PitchedProfile>
                        <Label fontStyle="h2" color="headerText">
                            Please choose the closest profile to your gutter.
                        </Label>

                        <Label fontStyle="h3" color="headerText">
                            or call technical support on 01298 875001
                        </Label>

                        <div style={{ position: "relative", marginTop: "10px" }}>
                            <EditSelect
                                noHeader={true}
                                displayName=""
                                placeholder="Please select a gutter profile"
                                options={SideTypeHelpers.getOptions()}
                                value={() => (viewModel.getSelectedGutterProfile as number).toString()}
                                command={viewModel.updateSideType}
                                validationMessage={() => viewModel.getError("sideType")}
                                formatOptionLabel={(option) => {
                                    return getImage(option.value);
                                }}
                            />
                        </div>
                    </PitchedProfile>
                )}
            </GutterDetailsWrapper>
            {roofType === RoofType.Pitched && viewModel.getSideType !== SideType.Unknown && (
                <ProfileWrapper>
                    <ProfileImage>
                        {viewModel.model.sideType === SideType.ThreeSideSym && (
                            <div>
                                <img src={ThreeSideSymLarge}></img>
                            </div>
                        )}
                        {viewModel.model.sideType === SideType.ThreeSideL && (
                            <div>
                                <img src={ThreeSideLeftLarge}></img>
                            </div>
                        )}
                        {viewModel.model.sideType === SideType.ThreeSideR && (
                            <div>
                                <img src={ThreeSideRightLarge}></img>
                            </div>
                        )}
                    </ProfileImage>
                    <ProfileEntry>
                        <ProfileEntryRow className="a">
                            <RoofcareInput
                                autoFill={false}
                                className="noClear profileValues"
                                displayClearButton={false}
                                type="number"
                                noHeader={false}
                                displayName="A length"
                                value={() => viewModel.getValue("side1Length")}
                                command={viewModel.updateSide1Length}
                                placeholder={"A"}
                                suffix={<SingleSuffix className="mmSuffix" text="mm" />}
                                validationMessage={() => viewModel.getError("side1Length")}
                                maskedOptions={MaskedOptions.NumbersOnly()}
                            />

                            <RoofcareInput
                                autoFill={false}
                                className="noClear profileValues"
                                displayClearButton={false}
                                type="number"
                                noHeader={false}
                                displayName="B length"
                                value={() => viewModel.getValue("bottomLength")}
                                command={viewModel.updateBottomLength}
                                placeholder={"B"}
                                suffix={<SingleSuffix className="mmSuffix" text="mm" />}
                                validationMessage={() => viewModel.getError("bottomLength")}
                                maskedOptions={MaskedOptions.NumbersOnly()}
                            />

                            <RoofcareInput
                                autoFill={false}
                                className="noClear"
                                displayClearButton={false}
                                type="number"
                                noHeader={false}
                                displayName="C length"
                                value={() => viewModel.getValue("side2Length")}
                                command={viewModel.updateSide2Length}
                                placeholder={"C"}
                                suffix={<SingleSuffix className="mmSuffix" text="mm" />}
                                validationMessage={() => viewModel.getError("side2Length")}
                                maskedOptions={MaskedOptions.NumbersOnly()}
                            />
                        </ProfileEntryRow>
                        <ProfileEntryRow className="b">
                            <RoofcareInput
                                autoFill={false}
                                className="noClear"
                                displayClearButton={false}
                                type="number"
                                noHeader={false}
                                displayName="X Angle"
                                value={() => viewModel.getValue("side1Angle")}
                                command={viewModel.updateSide1Angle}
                                placeholder={"X Angle"}
                                suffix={<SingleSuffix className="mmSuffix" text="&deg;" />}
                                validationMessage={() => viewModel.getError("side1Angle")}
                                maskedOptions={MaskedOptions.NumbersOnly()}
                            />
                            <RoofcareInput
                                autoFill={false}
                                className="noClear"
                                displayClearButton={false}
                                type="number"
                                noHeader={false}
                                displayName="Y Angle"
                                value={() => viewModel.getValue("side2Angle")}
                                command={viewModel.updateSide2Angle}
                                placeholder={"Y Angle"}
                                suffix={<SingleSuffix className="mmSuffix" text="&deg;" />}
                                validationMessage={() => viewModel.getError("side2Angle")}
                                maskedOptions={MaskedOptions.NumbersOnly()}
                            />
                        </ProfileEntryRow>
                        <ProfileEntryRow className="c">
                            <p>In the sensor packaging you will find RoofCare angle measure to assist with obtaining angles X&Y</p>
                        </ProfileEntryRow>
                    </ProfileEntry>
                </ProfileWrapper>
            )}
            <GutterDetailsWrapper padding={viewModel.isMobile ? "0 20px 0 20px" : "0px"}>
                {roofType === RoofType.Pitched && viewModel.getSideType !== SideType.Unknown && <ProfileSplitter />}
                {roofType === RoofType.Flat && (
                    <FlatProfile>
                        <h4>Flat roof maximum critical height</h4>
                        <div>
                            <RoofcareInput
                                className="noClear"
                                displayClearButton={false}
                                type="number"
                                noHeader={true}
                                displayName=""
                                value={() => viewModel.getValue("maxHeight")}
                                command={viewModel.updateMaxHeight}
                                placeholder={"Enter height..."}
                                suffix={<DoubleSuffix className1="heightSuffix" text1="height" className2="mmSuffix" text2="mm" />}
                                validationMessage={() => viewModel.getError("maxHeight")}
                                maskedOptions={MaskedOptions.NumbersOnly()}
                            />
                        </div>
                    </FlatProfile>
                )}
                <DrainageTypeBox>
                    <div>
                        <EditSelect
                            /* menuIsOpen={true} */
                            displayName="Drainage type"
                            options={viewModel.getDrainageTypeOptions}
                            value={() => drainageType.toString()}
                            // value={() => viewModel.getValue("drainageType")}
                            command={viewModel.updateDrainageType}
                            validationMessage={() => viewModel.getError("drainageType")}
                            /*                            formatOptionLabel={(option) => {
                                return getDropdown(option.text);
                            }} */
                        />
                    </div>

                    {drainageType === DrainageType.Gravity && <div></div>}

                    {drainageType === DrainageType.SingleSiphonic && (
                        <div>
                            <RoofcareInput
                                className="noClear"
                                displayClearButton={false}
                                type="number"
                                displayName="P1"
                                value={() => viewModel.getValue("p1")}
                                command={viewModel.updateP1}
                                placeholder={"Enter P1"}
                                suffix={<SingleSuffix className="mmSuffix" text="mm" />}
                                validationMessage={() => viewModel.getError("p1")}
                                maskedOptions={MaskedOptions.NumbersOnly()}
                            />
                        </div>
                    )}

                    {drainageType === DrainageType.DualSiphonic && (
                        <div>
                            <RoofcareInput
                                className="noClear"
                                displayClearButton={false}
                                type="number"
                                displayName="P1"
                                value={() => viewModel.getValue("p1")}
                                command={viewModel.updateP1}
                                placeholder={"Enter P1"}
                                suffix={<SingleSuffix className="mmSuffix" text="mm" />}
                                validationMessage={() => viewModel.getError("p1")}
                                maskedOptions={MaskedOptions.NumbersOnly()}
                            />
                            <RoofcareInput
                                className="noClear"
                                displayClearButton={false}
                                displayName="P2"
                                value={() => viewModel.getValue("p2")}
                                command={viewModel.updateP2}
                                placeholder={"Enter P2"}
                                suffix={<SingleSuffix className="mmSuffix" text="mm" />}
                                validationMessage={() => viewModel.getError("p2")}
                                maskedOptions={MaskedOptions.NumbersOnly()}
                            />
                        </div>
                    )}
                </DrainageTypeBox>

                <PreviousNext>
                    <PrevStepButton disabled={false} step={AssistantSteps.SensorLocation} buttonText={"Previous step"} onClick={onPrev} boxShadow={"0pt 3pt 6pt #00000059"} />
                    <NextStepButton disabled={!allowNextStep} step={AssistantSteps.InstallHeight} buttonText={"Next step"} onClick={onNext} boxShadow={"0pt 3pt 6pt #00000059"} />
                </PreviousNext>
            </GutterDetailsWrapper>
        </>
    );
});
